import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  addLiveApplication,
  updateLiveApplication,
} from "../../Network/ApplicationApi";
import { PlainList } from "flatlist-react";

const ApplicationCrud = (props) => {
  useEffect(() => {
    setSearch("");
  }, []);

  //redux dispatch
  const dispatch = useDispatch();

  const User = useSelector((state) => state.auth.user);
  const ActiveCred = useSelector((state) => state.cred.activeCred);
  const Accounts = useSelector((state) => state.account.accounts).filter(
    (account) => parseInt(account.deleted) === 0
  );
  const Creds = useSelector((state) => state.cred.creds);
  const [Search, setSearch] = useState("");
  const [Error, setError] = useState(false);
  const [Loading, setLoading] = useState(false);

  //edit application
  const EditApplication = async () => {
    let { credLinker, branchLinker, trace, live, deleted } = props.application;

    trace = Date.now();
    credLinker = ActiveCred.linker;
    branchLinker = ActiveCred.branchLinker;
    deleted = props.type === "delete" ? 1 : deleted;

    await updateLiveApplication(
      { ...props.application, trace, credLinker, deleted },
      User.token,
      dispatch
    );
  };

  const SaveApplication = async () => {
    let trace = Date.now();

    await addLiveApplication(
      {
        ...props.application,
        instLinker: ActiveCred.linker,
        credLinker: ActiveCred.linker,
        branchLinker: ActiveCred.branchLinker,
        live: 1,
        status: "inReview",
        trace,
        deleted: 0,
      },
      ActiveCred.instLinker,
      User.token,
      dispatch
    );
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();

    if (props.type === "add") {
      if (!props.application.client) {
        setError(true);
        return;
      }
      setLoading(true);
      await SaveApplication();
    } else {
      setLoading(true);
      await EditApplication();
    }
    setLoading(false);
    props.setShowModal(false);
  };

  const SearchedClients = Creds.filter(
    (client) =>
      (client.clientReg?.toLowerCase().search(Search.toLowerCase()) > -1 ||
        client.firstname?.toLowerCase().search(Search.toLowerCase()) > -1 ||
        client.lastname?.toLowerCase().search(Search.toLowerCase()) > -1 ||
        client.surname?.toLowerCase().search(Search.toLowerCase()) > -1 ||
        Search === "") &&
      parseInt(client.deleted) === 0 &&
      parseInt(client.client) === 1
  );

  const GetAccount =
    Accounts.find(
      (account) =>
        parseInt(account.linker) === parseInt(props.application.accountLinker)
    ) || {};

  return (
		<Modal
			show={props.ShowModal}
			onHide={() => props.setShowModal(false)}
			backdrop="static"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>
					<p className="text-capitalize text-center">
						{props.type} Application
					</p>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form onSubmit={HandleSubmit}>
					<div className="form-group">
						<label className="mb-2 text-capitalize"> Client Applying:</label>

						<small>
							{props.application.client?.clientReg}{" "}
							{props.application.client?.firstname}{" "}
							{props.application.client?.lastname}{" "}
							{props.application.client?.surname}
						</small>
						{!props.from ? (
							<>
								{" "}
								{Error ? (
									<small className="text-danger">No Client Selected</small>
								) : null}
								<Dropdown>
									<Dropdown.Toggle variant="transparent">
										<input
											className="rounded mb-0"
											placeholder={`Search Client`}
											value={Search}
											onChange={(e) => setSearch(e.target.value)}
										/>
									</Dropdown.Toggle>
									<Dropdown.Menu>
										<PlainList
											list={SearchedClients}
											renderOnScroll
											renderItem={(client, index) => (
												<Dropdown.Item
													key={index}
													onClick={() => {
														props.setApplication({
															...props.application,
															clientLinker: client.linker,
															client: client,
														});
														setError(false);
														setSearch(
															`${client.clientReg} ${client.firstname} ${client.lastname} ${client.surname}`
														);
													}}
												>
													{client.clientReg} {client.firstname}{" "}
													{client.lastname} {client.surname}
												</Dropdown.Item>
											)}
										/>
									</Dropdown.Menu>
								</Dropdown>
								<hr />
								{Accounts.length > 0 ? (
									<div className="form-group">
										<label className="mb-2 text-capitalize">
											Reference Account:
										</label>
										<select
											className="rounded form-control"
											value={props.application.accountLinker}
											onChange={(e) =>
												props.setApplication({
													...props.application,
													accountLinker: e.target.value,
												})
											}
											required
											readOnly={props.type === "delete" ? true : false}
										>
											<option value={""}>Select Account</option>
											{Accounts.map((account, index) => (
												<option key={index} value={account.linker}>
													{account.name}
												</option>
											))}
										</select>
										<hr />
									</div>
								) : null}
							</>
						) : null}
					</div>

					<div className="form-group">
						<label className="mb-2 text-capitalize">Amount Applied:</label>
						<input
							className="rounded form-control"
							placeholder={`enter amount applied`}
							value={props.application.appliedAmount}
							onChange={(e) =>
								props.setApplication({
									...props.application,
									appliedAmount: e.target.value,
								})
							}
							type="number"
							required
							readOnly={props.type === "delete" ? true : false}
						/>
						<hr />
					</div>
					{props.type === "review" ||
					(props.type === "edit" &&
						(props.application.status === "reviewed" ||
							props.application.status === "disbursed")) ? (
						<>
							<div className="form-group">
								<label className="mb-2 text-capitalize">Amount Approved:</label>
								<input
									className="rounded form-control"
									placeholder={`enter amount approved`}
									value={props.application.approvedAmount}
									onChange={(e) =>
										props.setApplication({
											...props.application,
											approvedAmount: e.target.value,
										})
									}
									type="number"
									required
									readOnly={props.type === "delete" ? true : false}
								/>
								<hr />
							</div>

							<div className="form-group">
								<label className="mb-2 text-capitalize">
									Date For Disbursement:
								</label>
								<input
									className="rounded form-control"
									placeholder={`enter disbursement date`}
									value={props.application.disburseDate}
									onChange={(e) =>
										props.setApplication({
											...props.application,
											disburseDate: e.target.value,
										})
									}
									type="date"
									onClick={(e) => e.target.showPicker()}
									required
									readOnly={props.type === "delete" ? true : false}
								/>
								<hr />
							</div>
						</>
					) : null}

					{props.type === "disburse" ||
					(props.type === "edit" &&
						props.application.status === "disbursed") ? (
						<div className="form-group">
							<label className="mb-2 text-capitalize">Amount Disbursed:</label>
							<input
								className="rounded form-control"
								placeholder={`enter amount disbursed`}
								value={props.application.disbursedAmount}
								onChange={(e) =>
									props.setApplication({
										...props.application,
										disbursedAmount: e.target.value,
									})
								}
								type="number"
								required
								readOnly={props.type === "delete" ? true : false}
							/>
							<hr />
						</div>
					) : null}

					{GetAccount.status === "loan" &&
					(props.type === "add" || props.type === "edit") ? (
						<>
							<div className="form-group">
								<label className="mb-2 text-capitalize">Loan Security:</label>
								<textarea
									className="rounded form-control"
									placeholder={`enter loan security`}
									value={props.application.security}
									onChange={(e) =>
										props.setApplication({
											...props.application,
											security: e.target.value,
										})
									}
									required
									readOnly={props.type === "delete" ? true : false}
								/>
								<hr />
							</div>
							<div className="form-group">
								<label className="mb-2 text-capitalize">Guarantors:</label>
								<textarea
									className="rounded form-control"
									placeholder={`enter loan guarantors`}
									value={props.application.guarantors}
									onChange={(e) =>
										props.setApplication({
											...props.application,
											guarantors: e.target.value,
										})
									}
									required
									readOnly={props.type === "delete" ? true : false}
								/>
								<hr />
							</div>
							<div className="form-group">
								<label className="mb-2 text-capitalize">
									Principal Amount:
								</label>
								<input
									className="rounded form-control"
									placeholder={`enter principal amount`}
									value={props.application.principal}
									onChange={(e) =>
										props.setApplication({
											...props.application,
											principal: e.target.value,
										})
									}
									readOnly={props.type === "delete" ? true : false}
									type={"number"}
								/>
								<hr />
							</div>
							<div className="form-group">
								<label className="mb-2 text-capitalize">
									Repayment Period In Months:
								</label>
								<input
									className="rounded form-control"
									placeholder={`enter repayment period`}
									value={props.application.period}
									onChange={(e) =>
										props.setApplication({
											...props.application,
											period: e.target.value,
										})
									}
									readOnly={props.type === "delete" ? true : false}
									type={"number"}
								/>
								<hr />
							</div>
						</>
					) : null}

					<div className="form-group">
						<label className="mb-2 text-capitalize">Application Details:</label>
						<textarea
							className="rounded form-control"
							placeholder={`enter application details`}
							value={props.application.details}
							onChange={(e) =>
								props.setApplication({
									...props.application,
									details: e.target.value,
								})
							}
							required
							readOnly={props.type === "delete" ? true : false}
						/>
						<hr />
					</div>

					<div className="d-flex justify-content-around mb-2">
						{props.type === "delete" ? (
							<Button variant="danger" type="submit">
								Delete
							</Button>
						) : (
							<Button variant="primary" type="submit">
								Save
							</Button>
						)}
						<Button
							variant="secondary"
							type="button"
							onClick={() => props.setShowModal(false)}
						>
							Cancel
						</Button>
					</div>
				</Form>
			</Modal.Body>
		</Modal>
	);
};

export default ApplicationCrud;
