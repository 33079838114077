import React from "react";
import { useSelector } from "react-redux";
import { Trash, PencilSquare } from "react-bootstrap-icons";

const ExpenseDisp = ({ expense, setShowModal, setType, setExpense, Cats }) => {
	const Creds = useSelector((state) => state.cred.creds);
	const Modes = useSelector((state) => state.mode.modes).filter(
		(mode) => parseInt(mode.deleted) === 0
	);

	const Groups = useSelector((state) => state.group.groups).filter(
		(group) => parseInt(group.deleted) === 0
	);

	const FindUser = (linker) => {
		let cred = Creds.find((cred) => parseInt(cred.linker) == parseInt(linker));

		return cred || { firstname: "unknown" };
	};

	const FindExpense = (linker) => {
		let mode = Modes.find((mode) => parseInt(mode.linker) === parseInt(linker));

		return mode || { name: "unknown" };
	};

	const FindCategory = (linker) => {
		let Cat = Cats.find((cat) => cat.linker == linker);
		return Cat || { name: "unknown" };
	};

	const FindGroup = (linker) => {
		let Branch = Groups.find(
			(branch) => parseInt(branch.linker) === parseInt(linker)
		);
		return Branch || { name: "unknown" };
	};

	return (
		<tr className="item">
			<td>{expense.name}</td>
			<td>{FindExpense(expense.mode).name}</td>
			<td>
				{expense.code} {expense.details}
			</td>
			<td>{expense.amount}</td>
			<td>{FindCategory(expense.catLinker).name}</td>
			<td>{new Date(parseInt(expense.linker)).toLocaleDateString("en-US")}</td>
			{Groups.length > 0 ? (
				<td>{FindGroup(expense.branchLinker).name}</td>
			) : null}
			<td>{FindUser(expense.credLinker).email}</td>
			<td>
				<PencilSquare
					onClick={() => {
						setShowModal(true);
						setType("edit");
						setExpense({ ...expense });
					}}
					style={{ cursor: "pointer" }}
					className="text-info mx-2"
				/>

				<Trash
					onClick={() => {
						setShowModal(true);
						setType("delete");
						setExpense({ ...expense });
					}}
					style={{ cursor: "pointer" }}
					className="text-danger mx-2"
				/>
			</td>
		</tr>
	);
};

export default ExpenseDisp;
