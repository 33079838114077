import React, { Component, lazy, Suspense } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./css/App.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./Pages/Home/LogIn";
import InstPrivacy from "./Pages/Home/InstPrivacy";
import InstTerms from "./Pages/Home/InstTerms";
import WebPay from "./Pages/SubPay/WebPay";
import MyEntries from "./Pages/Client/MyEntries";
import MyApplications from "./Pages/Client/MyApplications";
import Calc from "./Pages/Calc/Calc";
import ClientApplications from "./Pages/Applications/ClientApplications";
import Applications from "./Pages/Applications/Applications";
import Entries from "./Pages/Entries/Entries";
import ClientEntries from "./Pages/Entries/ClientEntries";
import Groups from "./Pages/Admin/Groups/Groups";
import Clients from "./Pages/Members/Clients";
import Accounts from "./Pages/Admin/Accounts/Accounts";
import Branchs from "./Pages/Admin/Branches/Branchs";
import Profile from "./Pages/Auth/Profile";
import EditInst from "./Pages/Inst/EditInst";
import Staffs from "./Pages/Admin/Staffs/Staffs";
import Home from "./Pages/Home/Home";
import ResetPassword from "./Pages/Auth/ResetPassword";
import Inst from "./Pages/Inst/Inst";
import Modes from "./Pages/Admin/Modes/Modes";
import ExpenseCats from "./Pages/Expenses/Categories/ExpenseCats";
import Expenses from "./Pages/Expenses/Expenses/Expenses";
import GroupExpenses from "./Pages/Expenses/GroupExpenses/Expenses";
import SoftwarePay2 from "./Pages/SubPay2/SoftwarePay2";
import WebPay2 from "./Pages/SubPay2/WebPay2";
import PayollCats from "./Pages/Admin/Payroll/Categories/Cats";
import PayrollItems from "./Pages/Admin/Staffs/StaffPayroll/PayrollItems";
import PayrollSummary from "./Pages/Admin/Payroll/PayrollSummary";
import MyPaySlips from "./Pages/Auth/MyPaySlips";
import Notifications from "./Pages/Notifications/Notifications";
import MpesaOnline from "./Pages/Admin/OnlinePay/Mpesa/SetUp/MpesaOnline";
import MpesaPayments from "./Pages/Admin/OnlinePay/Mpesa/Payments/MpesaPayments";
import BulkSms from "./Pages/Admin/Sms/BulkSms";
import Dividends from "./Pages/Admin/Dividend/Dividends";
import IncomeStatement from "./Pages/Profit/IncomeStatement";

const Terms = lazy(() => import("./Pages/Home/Terms"));
const Privacy = lazy(() => import("./Pages/Home/Privacy"));
const SoftwarePay = lazy(() => import("./Pages/SubPay/SoftwarePay"));
const HomeDash = lazy(() => import("./Pages/Dashboard/HomeDash"));
const Dashboard = lazy(() => import("./Pages/Dashboard/Dashboard"));

class App extends Component {
	render() {
		return (
			<div>
				<BrowserRouter basename="/">
					<Suspense
						fallback={
							<div className="d-flex justify-content-center align-items-center">
								<span className="spinner-border text-primary"></span>
							</div>
						}
					>
						<Routes>
							<Route path="/" element={<Home />} />
							<Route path="/reset/:reset/:logger" element={<ResetPassword />} />
							<Route path="inst" element={<Inst />} />
							<Route path="login" element={<Login />}></Route>
							<Route path="terms" element={<Terms />}></Route>
							<Route path="privacy" element={<Privacy />}></Route>
							<Route path="activate" element={<SoftwarePay />} />
							{
								//Route to for version two of software payments
								<Route path="activate-software" element={<SoftwarePay2 />} />
							}
							<Route path=":instName" element={<Dashboard />}>
								<Route
									path="activate/:instName/:clientId"
									element={<WebPay />}
								/>
								{
									//Route for version two of web payments
									<Route
										path="activate-web/:instName/:clientId"
										element={<WebPay2 />}
									></Route>
								}
								<Route path="/:instName" element={<HomeDash />}></Route>
								<Route path="terms" element={<InstTerms />}></Route>
								<Route path="privacy" element={<InstPrivacy />}></Route>
								<Route path="edit" element={<EditInst />}></Route>
								<Route path="profile" element={<Profile />}></Route>{" "}
								<Route path="dividend" element={<Dividends />}></Route>{" "}
								<Route
									path="income-statement"
									element={<IncomeStatement />}
								></Route>{" "}
								<Route path="payslips" element={<MyPaySlips />}></Route>
								<Route path="staffs" element={<Staffs />}></Route>
								<Route path="payroll-items" element={<PayollCats />}></Route>
								<Route
									path="staffs/payroll-items/:staffName/:staffNo/:staffLinker"
									element={<PayrollItems />}
								></Route>
								<Route
									path="payroll-summary"
									element={<PayrollSummary />}
								></Route>
								<Route path="notifications" element={<Notifications />}></Route>
								<Route path="bulk-sms" element={<BulkSms />}></Route>
								<Route path="modes" element={<Modes />}></Route>
								<Route
									path="modes/mpesa-setup"
									element={<MpesaOnline />}
								></Route>
								<Route path="expensecats" element={<ExpenseCats />}></Route>
								<Route path="expenses" element={<Expenses />}></Route>
								<Route
									path="group-expenses"
									element={<GroupExpenses />}
								></Route>
								<Route path="branches" element={<Branchs />}></Route>
								<Route path="groups" element={<Groups />}></Route>
								<Route path="accounts" element={<Accounts />}></Route>
								<Route path="clients" element={<Clients />}></Route>{" "}
								<Route
									path="clients/client/entries/:accountLinker/:clientLinker"
									element={<ClientEntries></ClientEntries>}
								></Route>
								<Route
									path="clients/client/applications/:accountLinker/:clientLinker"
									element={<ClientApplications></ClientApplications>}
								></Route>
								<Route path="entries" element={<Entries />}></Route>
								<Route
									path="mpesa-payments"
									element={<MpesaPayments />}
								></Route>
								<Route path="applications" element={<Applications />}></Route>
								<Route
									path="client/applications"
									element={<MyApplications />}
								></Route>
								<Route
									path="client/entries/:accountLinker"
									element={<MyEntries />}
								></Route>
								<Route path="calculator" element={<Calc />}></Route>
							</Route>

							{/*<Route path="*" element={<NotFound />} />*/}
						</Routes>
					</Suspense>
				</BrowserRouter>
			</div>
		);
	}
}

export default App;
