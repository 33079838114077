import { createSlice } from "@reduxjs/toolkit";

export const BranchSlice = createSlice({
  name: "branch",
  initialState: {
    branchs: [],
  },
  reducers: {
    fetchBranchs: (state, action) => {
      state.branchs = action.payload;
    },
    addBranch: (state, action) => {
      state.branchs = [action.payload, ...state.branchs];
    },
    updateBranch: (state, action) => {
      let index = state.branchs.findIndex(
        (branch) => parseInt(branch.linker) === parseInt(action.payload.linker)
      );

      let prevBranchs = [...state.branchs];

      prevBranchs.splice(
        index < 0 ? 1 : index,
        index < 0 ? 0 : 1,
        action.payload
      );

      state.branchs = prevBranchs;
    },
    deleteBranch: (state, action) => {
      let newBranchs = state.branchs.filter(
        (branch) => parseInt(branch.linker) !== parseInt(action.payload.linker)
      );
      state.branchs = newBranchs;
    },
  },
});

export const { fetchBranchs, addBranch, updateBranch, deleteBranch } =
  BranchSlice.actions;

export default BranchSlice.reducer;
