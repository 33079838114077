import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { addLiveEntry, updateLiveEntry } from "../../Network/EntryApi";

const EntryCrud = (props) => {
	//redux dispatch
	const dispatch = useDispatch();

	const User = useSelector((state) => state.auth.user);
	const ActiveCred = useSelector((state) => state.cred.activeCred);
	const Modes = useSelector((state) => state.mode.modes).filter(
		(mode) => parseInt(mode.deleted) === 0
	);
	const [Loading, setLoading] = useState(false);

	//edit entry
	const EditEntry = async () => {
		let trace = Date.now();
		let credLinker = ActiveCred.linker;
		let deleted = props.type === "delete" ? 1 : 0;

		await updateLiveEntry(
			{ ...props.entry, trace, credLinker, deleted },
			User.token,
			dispatch
		);
	};

	const SaveEntry = async () => {
		let trace = Date.now();

		await addLiveEntry(
			{
				...props.entry,
				instLinker: ActiveCred.linker,
				credLinker: ActiveCred.linker,
				live: 1,
				status: 0,
				trace,
				deleted: 0,
				linker: trace,
			},
			ActiveCred.instLinker,
			User.token,
			dispatch
		);
		if (props.entry.type === "issued-loan") {
			await addLiveEntry(
				{
					...props.entry,
					instLinker: ActiveCred.linker,
					credLinker: ActiveCred.linker,
					live: 1,
					status: 0,
					trace: Date.now(),
					deleted: 0,
					linker: Date.now(),
					amount: (props.entry.amount * props.account.rate) / 100,
					type: `loan-interest`,
					details: `${
						new Date().getMonth() + 1
					}/${new Date().getFullYear()} Interest`,
					security: "",
					guarantors: "",
					principal: "",
					period: "",
				},
				ActiveCred.instLinker,
				User.token,
				dispatch
			);
		}
	};

	const HandleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		if (props.type === "edit" || props.type === "delete") {
			await EditEntry();
		} else {
			await SaveEntry();
		}
		setLoading(false);
		props.setShowModal(false);
	};

	return (
		<Modal
			show={props.ShowModal}
			onHide={() => props.setShowModal(false)}
			backdrop="static"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>
					<div>
						<p className="text-capitalize text-center m-0 p-0">
							{props.client.clientReg} {props.client.firstname}{" "}
							{props.client.lastname} {props.client.surname}
						</p>
						<p className="text-capitalize text-center m-0 p-0">
							{props.type} {props.account.name}
						</p>
					</div>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form onSubmit={HandleSubmit}>
					<div className="form-group">
						<label className="mb-2 text-capitalize">Amount Transacted:</label>
						<input
							className="rounded form-control"
							placeholder={`enter amount`}
							value={props.entry.amount}
							onChange={(e) =>
								props.setEntry({
									...props.entry,
									amount: e.target.value,
								})
							}
							type="number"
							required
							readOnly={props.type === "delete" ? true : false}
						/>
						<hr />
					</div>

					<div className="form-group">
						<label className="mb-2 text-capitalize">Transaction Type:</label>
						<select
							className="rounded form-control"
							value={props.entry.type}
							onChange={(e) =>
								props.setEntry({
									...props.entry,
									type: e.target.value,
								})
							}
							required
							readOnly={props.type === "delete" ? true : false}
						>
							<option value={""}>Select Transaction Type</option>
							{props.account.status === "saving" &&
							(props.type === "add" ||
								props.entry.type === "saving" ||
								props.entry.type === "savings-interest") ? (
								<>
									<option value="saving">Savings</option>
									<option value="savings-interest">Savings Interest</option>
								</>
							) : props.account.status === "saving" &&
							  (props.type === "subtract" ||
									props.entry.type === "withdrawal" ||
									props.entry.type === "fine") ? (
								<>
									<option value="withdrawal">Withdrawal</option>
									<option value="fine">Fine Charges</option>
								</>
							) : props.account.status === "loan" &&
							  (props.type === "add" ||
									props.entry.type === "issued-loan" ||
									props.entry.type === "loan-interest") ? (
								<>
									<option value="issued-loan">Loan Issued</option>
									<option value="loan-interest">Loan Interest</option>
								</>
							) : props.account.status === "loan" &&
							  (props.type === "subtract" ||
									props.entry.type === "payment") ? (
								<>
									<option value="payment">Loan Payment</option>
								</>
							) : null}
						</select>
						<hr />
					</div>
					{props.entry.type === "issued-loan" ? (
						<>
							<div className="form-group">
								<label className="mb-2 text-capitalize">Loan Security:</label>
								<textarea
									className="rounded form-control"
									placeholder={`enter loan security`}
									value={props.entry.security}
									onChange={(e) =>
										props.setEntry({
											...props.entry,
											security: e.target.value,
										})
									}
									readOnly={props.type === "delete" ? true : false}
								/>
								<hr />
							</div>{" "}
							<div className="form-group">
								<label className="mb-2 text-capitalize">Loan Guarantors:</label>
								<textarea
									className="rounded form-control"
									placeholder={`enter loan guarantors`}
									value={props.entry.guarantors}
									onChange={(e) =>
										props.setEntry({
											...props.entry,
											guarantors: e.target.value,
										})
									}
									readOnly={props.type === "delete" ? true : false}
								/>
								<hr />
							</div>
							<div className="form-group">
								<label className="mb-2 text-capitalize">Loan Principal:</label>
								<input
									className="rounded form-control"
									placeholder={`enter loan principal`}
									value={props.entry.principal}
									onChange={(e) =>
										props.setEntry({
											...props.entry,
											principal: e.target.value,
										})
									}
									readOnly={props.type === "delete" ? true : false}
									type="number"
								/>
								<hr />
							</div>
							<div className="form-group">
								<label className="mb-2 text-capitalize">
									Loan Repayment Duration <em>{"{In Months}"}</em>:{" "}
								</label>
								<input
									className="rounded form-control"
									placeholder={`enter loan repayment duration`}
									value={props.entry.period}
									onChange={(e) =>
										props.setEntry({
											...props.entry,
											period: e.target.value,
										})
									}
									readOnly={props.type === "delete" ? true : false}
									type="number"
								/>
								<hr />
							</div>
						</>
					) : null}
					<div className="form-group">
						<label className="mb-2 text-capitalize">Transaction Details:</label>
						<textarea
							className="rounded form-control"
							placeholder={`enter transaction details`}
							value={props.entry.details}
							onChange={(e) =>
								props.setEntry({
									...props.entry,
									details: e.target.value,
								})
							}
							required
							readOnly={props.type === "delete" ? true : false}
						/>
						<hr />
					</div>
					{Modes.length > 0 &&
					props.entry.type !== "savings-interest" &&
					props.entry.type !== "loan-interest" ? (
						<div className="form-group">
							<label className="mb-2 text-capitalize">Mode Of Payment:</label>
							<select
								className="rounded form-control"
								value={props.entry.modeLinker}
								onChange={(e) =>
									props.setEntry({
										...props.entry,
										modeLinker: e.target.value,
									})
								}
								required
								readOnly={props.type === "delete" ? true : false}
							>
								<option value={""}>Select Payment Mode</option>
								{Modes.map((mode, index) => (
									<option key={index} value={mode.linker}>
										{mode.name}
									</option>
								))}
							</select>
							<hr />
						</div>
					) : null}
					<div className="form-group">
						<label className="mb-2 text-capitalize">
							Transaction/Receipt No:
						</label>
						<input
							className="rounded form-control"
							placeholder={`enter trasaction/receipt No`}
							value={props.entry.code}
							onChange={(e) =>
								props.setEntry({
									...props.entry,
									code: e.target.value,
								})
							}
							type="text"
							required
							readOnly={props.type === "delete" ? true : false}
						/>
						<hr />
					</div>
					<div className="d-flex justify-content-around mb-2">
						{Loading ? (
							<span className="spinner-border text-primary"></span>
						) : props.type === "delete" ? (
							<Button variant="danger" type="submit">
								Delete
							</Button>
						) : (
							<Button variant="primary" type="submit">
								Save
							</Button>
						)}
						<Button
							variant="secondary"
							type="button"
							onClick={() => props.setShowModal(false)}
						>
							Cancel
						</Button>
					</div>
				</Form>
			</Modal.Body>
		</Modal>
	);
};

export default EntryCrud;
