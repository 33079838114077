import React from "react";

const NoApplication = () => {
  return (
    <tbody>
      <tr>
        <th>No Applications</th>

        <td>
          Applications are various clients request involving their accounts eg
          withdrawal or loan application
        </td>
      </tr>
    </tbody>
  );
};

export default NoApplication;
