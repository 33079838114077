import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { addLiveBranch, updateLiveBranch } from "../../../Network/BranchApi";

const BranchCrud = (props) => {
  //redux dispatch
  const dispatch = useDispatch();

  const ActiveCred = useSelector((state) => state.cred.activeCred);
  const User = useSelector((state) => state.auth.user);
  const [Loading, setLoading] = useState(false);

  //add branch
  const AddBranch = async () => {
    let linker = Date.now();

    await addLiveBranch(
      {
        details: props.branch.details,
        linker,
        name: props.branch.name,
        instLinker: ActiveCred.linker,
        credLinker: ActiveCred.linker,
        live: 1,
        status: 0,
        trace: linker,
        deleted: 0,
      },
      ActiveCred.instLinker,
      User.token,
      dispatch
    );

    props.setBranch({
      ...props.branch,
      name: "",
      details: "",
    });
  };

  //edit branch
  const EditBranch = async () => {
    let {
      credLinker,

      trace,

      deleted,
    } = props.branch;

    trace = Date.now();
    credLinker = ActiveCred.linker;
    deleted = props.type === "delete" ? 1 : deleted;

    await updateLiveBranch(
      { ...props.branch, trace, credLinker, deleted },
      User.token,
      dispatch
    );
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (props.type === "add") {
      await AddBranch();
    } else {
      await EditBranch();
    }
    setLoading(false);
    props.setShowModal(false);
  };

  return (
    <Modal
      show={props.ShowModal}
      onHide={() => props.setShowModal(false)}
      backdrop="static"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <p className="text-capitalize text-center">{props.type} Branch</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={HandleSubmit}>
          <div className="form-group">
            <label className="mb-2 text-capitalize"> Branch Name:</label>
            <input
              className="rounded form-control"
              placeholder={`enter branch name`}
              value={props.branch.name}
              onChange={(e) =>
                props.setBranch({
                  ...props.branch,
                  name: e.target.value,
                })
              }
              required
              readOnly={props.type === "delete" ? true : false}
            />
            <hr />
          </div>
          <div className="form-group">
            <label className="mb-2 text-capitalize">Branch Details:</label>
            <textarea
              className="rounded form-control"
              placeholder={`enter branch details`}
              value={props.branch.details}
              onChange={(e) =>
                props.setBranch({
                  ...props.branch,
                  details: e.target.value,
                })
              }
              required
              readOnly={props.type === "delete" ? true : false}
            />
            <hr />
          </div>
          <div className="d-flex justify-content-around mb-2">
            {Loading ? (
              <span className="spinner-border text-primary"></span>
            ) : props.type === "delete" ? (
              <Button variant="danger" type="submit">
                Delete
              </Button>
            ) : (
              <Button variant="primary" type="submit">
                Save
              </Button>
            )}
            <Button
              variant="secondary"
              type="button"
              onClick={() => props.setShowModal(false)}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default BranchCrud;
