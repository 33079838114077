import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RegisterLiveCred, updateLiveCred } from "../../Network/CredApi";

const ClientCrud = (props) => {
  //redux dispatch
  const dispatch = useDispatch();

  const User = useSelector((state) => state.auth.user);
  const ActiveCred = useSelector((state) => state.cred.activeCred);
  const Groups = useSelector((state) => state.group.groups).filter(
    (group) => parseInt(group.deleted) === 0
  );
  const [Loading, setLoading] = useState(false);

  //add client
  const AddClient = async () => {
    let linker = Date.now();

    await RegisterLiveCred(
      {
        ...props.client,
        linker,
        instLinker: ActiveCred.linker,
        credLinker: ActiveCred.linker,
        live: 1,
        trace: linker,
        deleted: 0,
      },
      "member/add",
      User.token,
      dispatch
    );
  };

  //edit client
  const EditClient = async () => {
    let { credLinker, trace, live, deleted } = props.client;

    trace = Date.now();
    credLinker = ActiveCred.linker;
    deleted = props.type === "delete" ? 1 : deleted;

    await updateLiveCred(
      { ...props.client, trace, credLinker, deleted },
      "member/edit",
      User.token,
      dispatch
    );
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (props.type === "add") {
      await AddClient();
    } else {
      await EditClient();
    }
    setLoading(false);
    props.setShowModal(false);
  };

  return (
    <div>
      <Modal
        show={props.ShowModal}
        onHide={() => props.setShowModal(false)}
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <strong className="text-capitalize text-center">
            {props.type} Client:{" "}
          </strong>
          <br />
          {props.type === "delete" ? null : (
            <small style={{ fontSize: "16" }}>Fill Whats Applicable</small>
          )}
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={HandleSubmit}>
            <div className="form-group">
              <div className="row">
                {Groups.length > 0 ? (
                  <div className="col-md-6">
                    <label className="mb-2 text-capitalize">
                      Select Client Group:
                    </label>
                    <select
                      className="rounded form-control"
                      value={props.client.groupLinker}
                      onChange={(e) =>
                        props.setClient({
                          ...props.client,
                          groupLinker: e.target.value,
                        })
                      }
                      readOnly={props.type === "delete" ? true : false}
                    >
                      <option value={""}>No Group</option>
                      {Groups.map((group) => (
                        <option key={group.linker} value={group.linker}>
                          {group.name}
                        </option>
                      ))}
                    </select>
                    <hr />
                  </div>
                ) : null}

                <div className="col-md-6">
                  <label className="mb-2 text-capitalize">Client A/C No:</label>
                  <input
                    className="rounded form-control"
                    placeholder={`Enter client A/C No`}
                    value={props.client.clientReg}
                    onChange={(e) =>
                      props.setClient({
                        ...props.client,
                        clientReg: e.target.value,
                      })
                    }
                    readOnly={props.type === "delete" ? true : false}
                  />
                  <hr />
                </div>
                <div className="col-md-6">
                  <label className="mb-2 text-capitalize">Surname:</label>
                  <input
                    className="rounded form-control"
                    placeholder={`Enter Surname`}
                    value={props.client.surname}
                    onChange={(e) =>
                      props.setClient({
                        ...props.client,
                        surname: e.target.value,
                      })
                    }
                    readOnly={props.type === "delete" ? true : false}
                  />
                  <hr />
                </div>
                <div className="col-md-6">
                  <label className="mb-2 text-capitalize">FirstName:</label>
                  <input
                    className="rounded form-control"
                    placeholder={`Enter firstname`}
                    value={props.client.firstname}
                    onChange={(e) =>
                      props.setClient({
                        ...props.client,
                        firstname: e.target.value,
                      })
                    }
                    readOnly={props.type === "delete" ? true : false}
                  />
                  <hr />
                </div>
                <div className="col-md-6">
                  <label className="mb-2 text-capitalize">LastName:</label>
                  <input
                    className="rounded form-control"
                    placeholder={`Enter lastname`}
                    value={props.client.lastname}
                    onChange={(e) =>
                      props.setClient({
                        ...props.client,
                        lastname: e.target.value,
                      })
                    }
                    readOnly={props.type === "delete" ? true : false}
                  />
                  <hr />
                </div>
                <div className="col-md-6">
                  <label className="mb-2 text-capitalize">Email:</label>
                  <input
                    className="rounded form-control"
                    placeholder={`Enter email`}
                    value={props.client.email}
                    onChange={(e) =>
                      props.setClient({
                        ...props.client,
                        email: e.target.value.toLowerCase(),
                      })
                    }
                    type="email"
                    readOnly={props.type === "delete" ? true : false}
                  />
                  <hr />
                </div>
                <div className="col-md-6">
                  <label className="mb-2 text-capitalize">Contact:</label>
                  <input
                    className="rounded form-control"
                    placeholder={`Enter contact`}
                    value={props.client.contact}
                    onChange={(e) =>
                      props.setClient({
                        ...props.client,
                        contact: e.target.value,
                      })
                    }
                    readOnly={props.type === "delete" ? true : false}
                  />
                  <hr />
                </div>
                <div className="col-md-6">
                  <label className="mb-2 text-capitalize">
                    Location/Residence:
                  </label>
                  <input
                    className="rounded form-control"
                    placeholder={`Enter location`}
                    value={props.client.location}
                    onChange={(e) =>
                      props.setClient({
                        ...props.client,
                        location: e.target.value,
                      })
                    }
                    readOnly={props.type === "delete" ? true : false}
                    type="text"
                  />
                  <hr />
                </div>
                <div className="col-md-6">
                  <label className="mb-2 text-capitalize">
                    {" "}
                    Source Of Income:
                  </label>
                  <input
                    onChange={(e) =>
                      props.setClient({
                        ...props.client,
                        income: e.target.value,
                      })
                    }
                    readOnly={props.type === "delete" ? true : false}
                    value={props.client.income}
                    placeholder={"Enter source of income"}
                    className="rounded form-control"
                  />

                  <hr />
                </div>
                <div className="col-md-6">
                  <label className="mb-2 text-capitalize">Other Details:</label>
                  <textarea
                    onChange={(e) =>
                      props.setClient({
                        ...props.client,
                        details: e.target.value,
                      })
                    }
                    readOnly={props.type === "delete" ? true : false}
                    value={props.client.details}
                    className="rounded form-control"
                    placeholder="Enter Other client Details"
                  />

                  <hr />
                </div>

                <div className="col-md-6">
                  <label className="mb-2 text-capitalize">
                    Next Of Kin Name:
                  </label>
                  <input
                    className="rounded form-control"
                    placeholder={`Enter next of kin name`}
                    value={props.client.nokName}
                    onChange={(e) =>
                      props.setClient({
                        ...props.client,
                        nokName: e.target.value,
                      })
                    }
                    readOnly={props.type === "delete" ? true : false}
                  />
                  <hr />
                </div>
                <div className="col-md-6">
                  <label className="mb-2 text-capitalize">
                    Next Of Kin Contact:
                  </label>
                  <input
                    className="rounded form-control"
                    placeholder={`Enter next of kin contact`}
                    value={props.client.nokContact}
                    onChange={(e) =>
                      props.setClient({
                        ...props.client,
                        nokContact: e.target.value,
                      })
                    }
                    readOnly={props.type === "delete" ? true : false}
                  />
                  <hr />
                </div>
              </div>
            </div>
            <hr />
            <div className="d-flex justify-content-around mb-2">
              {Loading ? (
                <span className="spinner-border text-primary"></span>
              ) : props.type === "delete" ? (
                <Button variant="danger" type="submit">
                  Delete
                </Button>
              ) : (
                <Button variant="primary" type="submit">
                  Save
                </Button>
              )}
              <Button
                variant="secondary"
                type="button"
                onClick={() => props.setShowModal(false)}
              >
                Cancel
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ClientCrud;
