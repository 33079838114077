import {
	fetchMpesaPayments,
	updateMpesaPayment,
} from "../reducers/MpesaPaymentsSlice";
import { ApiUrl } from "./Urls";

export const FetchMpesaPayments = async (
	token,
	instLinker,
	dispatch,
	online,
	from = "fetch",
	payload = {}
) => {
	if (from === "fetch") {
		FetchLiveMpesaPayments(token, instLinker, online, dispatch);
	} else if (parseInt(instLinker) === parseInt(payload.instLinker)) {
		dispatch(updateMpesaPayment(payload));
	}
};

export const FetchLiveMpesaPayments = (token, instLinker, online, dispatch) => {
	fetch(`${ApiUrl}/entry/mpesa`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			online,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(fetchMpesaPayments(res.mpesaPayments));
			}
		})
		.catch(() => {});
};
