import { fetchEntrys } from "../reducers/EntrySlice";
import {
  addApplication,
  fetchApplications,
  updateApplication,
} from "../reducers/ApplicationSlice";
import { ApiUrl } from "./Urls";
import swal from "sweetalert";

export const FetchClientEntries = (token, instLinker, dispatch) => {
  fetch(`${ApiUrl}/client/entries`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    },
    body: JSON.stringify({
      instLinker,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 200) {
        dispatch(fetchEntrys(res.entries));
      } else {
        swal({
          title: "Failed to connect",
          icon: "warning",
          timer: 3000,
        });
      }
    })
    .catch(() => {
      swal({
        title: "Failed to connect",
        icon: "warning",
        timer: 3000,
      });
    });
};

export const FetchClientApplications = (token, instLinker, dispatch) => {
  fetch(`${ApiUrl}/client/applications`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    },
    body: JSON.stringify({
      instLinker,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 200) {
        dispatch(fetchApplications(res.applications));
      } else {
        swal({
          title: "Failed to connect",
          icon: "warning",
          timer: 3000,
        });
      }
    })
    .catch(() => {
      swal({
        title: "Failed to connect",
        icon: "warning",
        timer: 3000,
      });
    });
};

export const ClientApply = (
  token,
  instLinker,
  accountLinker,
  appliedAmount,
  details,
  dispatch,
  id,
  link,
  setLoading,
  setShowModal
) => {
  setLoading(true);
  fetch(`${ApiUrl}/client/${link}`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    },
    body: JSON.stringify({
      instLinker,
      accountLinker,
      appliedAmount,
      details,
      id,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      setLoading(false);
      if (res.status === 201) {
        setShowModal(false);
        if (link === "add") {
          dispatch(updateApplication(res.application));
        } else {
          dispatch(updateApplication(res.application));
        }
      } else {
        swal({
          title: "Application failed",
          text: "Try Again",
          icon: "error",
        });
      }
    })
    .catch(() =>
      swal({
        title: "Application failed",
        text: "Try Again",
        icon: "error",
      })
    );
};
