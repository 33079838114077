import swal from "sweetalert";
import { addGroup, fetchGroups, updateGroup } from "../reducers/GroupSlice";
import { ApiUrl } from "./Urls";

export const FetchGroups = async (
  token,
  instLinker,
  dispatch,
  online,
  from = "fetch",
  payload = {}
) => {
  if (from === "fetch") {
    FetchLiveGroups(token, instLinker, online, dispatch);
  } else if (parseInt(instLinker) === parseInt(payload.instLinker)) {
    dispatch(updateGroup(payload));
  }
};

export const addLiveGroup = (group, instLinker, token, dispatch) => {
  return fetch(`${ApiUrl}/group/add`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    },
    body: JSON.stringify({
      ...group,
      instLinker,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 201) {
        dispatch(updateGroup(res.group));
      } else {
        swal({
          title: "Failed to connect",
          icon: "warning",
          timer: 3000,
        });
      }
    })
    .catch(() => {
      swal({
        title: "Failed to connect",
        icon: "warning",
        timer: 3000,
      });
    });
};

export const updateLiveGroup = (group, token, dispatch) => {
  return fetch(`${ApiUrl}/group/edit`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    },
    body: JSON.stringify({
      ...group,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 200) {
        dispatch(updateGroup(res.group));
      } else {
        swal({
          title: "Failed to connect",
          icon: "warning",
          timer: 3000,
        });
      }
    })
    .catch(() => {
      swal({
        title: "Failed to connect",
        icon: "warning",
        timer: 3000,
      });
    });
};

export const FetchLiveGroups = (token, instLinker, online, dispatch) => {
  fetch(`${ApiUrl}/group/get`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    },
    body: JSON.stringify({
      online,
      instLinker,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 200) {
        dispatch(fetchGroups(res.groups));
      }
    })
    .catch(() => {});
};
