import { createSlice } from "@reduxjs/toolkit";

export const EntrySlice = createSlice({
  name: "entry",
  initialState: {
    entrys: [],
  },
  reducers: {
    fetchEntrys: (state, action) => {
      state.entrys = action.payload;
    },
    addEntry: (state, action) => {
      state.entrys = [action.payload, ...state.entrys];
    },
    updateEntry: (state, action) => {
      let index = state.entrys.findIndex(
        (entry) => parseInt(entry.linker) === parseInt(action.payload.linker)
      );

      let prevEntrys = [...state.entrys];

      prevEntrys.splice(
        index < 0 ? 1 : index,
        index < 0 ? 0 : 1,
        action.payload
      );

      state.entrys = prevEntrys;
    },
    deleteEntry: (state, action) => {
      let newEntrys = state.entrys.filter(
        (entry) => parseInt(entry.linker) !== parseInt(action.payload.linker)
      );
      state.entrys = newEntrys;
    },
  },
});

export const { fetchEntrys, addEntry, updateEntry, deleteEntry } =
  EntrySlice.actions;

export default EntrySlice.reducer;
