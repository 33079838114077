import React from "react";
import { useSelector } from "react-redux";
import { Trash, PencilSquare } from "react-bootstrap-icons";
import { Dropdown } from "react-bootstrap";

const ApplicationDisp = ({
  application,
  setShowModal,
  setType,
  setApplication,
  Amount,
}) => {
  const Creds = useSelector((state) => state.cred.creds);
  const Accounts = useSelector((state) => state.account.accounts).filter(
    (account) => parseInt(account.deleted) === 0
  );
  const Groups = useSelector((state) => state.group.groups).filter(
    (group) => parseInt(group.deleted) === 0
  );
  const Branches = useSelector((state) => state.branch.branchs).filter(
    (branch) => parseInt(branch.deleted) === 0
  );

  const FindUser = (linker) => {
    let cred = Creds.find((cred) => parseInt(cred.linker) == parseInt(linker));

    return cred || { firstname: "unknown" };
  };

  const FindAccount = (linker) => {
    let account = Accounts.find(
      (account) => parseInt(account.linker) === parseInt(linker)
    );

    return account || { name: "unknown" };
  };

  const FindGroup = (linker) => {
    let group = Groups.find(
      (group) => parseInt(group.linker) === parseInt(linker)
    );

    return group || { name: "unknown" };
  };
  const FindBranch = (linker) => {
    let branch = Branches.find(
      (branch) => parseInt(branch.linker) === parseInt(linker)
    );

    return branch || { name: "unknown" };
  };

  return (
    <tr className="item">
      <td>
        <Dropdown>
          <Dropdown.Toggle variant="transparent">
            {FindUser(application.clientLinker).clientReg ||
              FindUser(application.clientLinker).firstname}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item>
              <strong>Name : </strong>
              <em>
                {FindUser(application.clientLinker).firstname}{" "}
                {FindUser(application.clientLinker).lastname}{" "}
                {FindUser(application.clientLinker).surname}
              </em>
            </Dropdown.Item>
            <Dropdown.Item>
              <strong>Contact : </strong>
              <em>{FindUser(application.clientLinker).contact} </em>
            </Dropdown.Item>
            <Dropdown.Item>
              <strong>Email : </strong>
              <em>{FindUser(application.clientLinker).email} </em>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </td>
      {Accounts.length > 0 ? (
        <td>{FindAccount(application.accountLinker).name}</td>
      ) : null}
      {Groups.length > 0 ? (
        <td>
          {FindGroup(FindUser(application.clientLinker).groupLinker).name}
        </td>
      ) : null}
      {Branches.length > 0 ? (
        <td>{FindBranch(application.branchLinker).name}</td>
      ) : null}
      <td>{application.status}</td>
      <td>
        {FindAccount(application.accountLinker).status === "loan" ? (
          <Dropdown>
            <Dropdown.Toggle variant="transparent">
              {application.details}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>
                <strong>Security : </strong>
                <em>{application.security}</em>
              </Dropdown.Item>
              <Dropdown.Item>
                <strong>Guarantors : </strong>
                <em>{application.guarantors} </em>
              </Dropdown.Item>
              <Dropdown.Item>
                <strong>Principal Amount: </strong>
                <em>{application.principal} </em>
              </Dropdown.Item>
              <Dropdown.Item>
                <strong>Repayment Period: </strong>
                <em>{application.period} </em>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ) : (
          application.details
        )}
      </td>
      <td>
        <Dropdown>
          <Dropdown.Toggle variant="transparent">
            {application[Amount]}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item>
              <strong>Applied Amount : </strong>
              <em>{application.appliedAmount}</em>
            </Dropdown.Item>
            <Dropdown.Item>
              <strong>Approved Amount : </strong>
              <em>{application.approvedAmount} </em>
            </Dropdown.Item>
            <Dropdown.Item>
              <strong>Disbursed Amount: </strong>
              <em>{application.disbursedAmount} </em>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </td>

      <td>{application.disburseDate}</td>
      <td>
        {new Date(parseInt(application.linker)).toLocaleDateString("en-US")}
      </td>
      <td>{FindUser(application.credLinker).email}</td>
      <td>
        {application.status === "inReview" ? (
          <span
            onClick={() => {
              setShowModal(true);
              setType("review");
              setApplication({
                ...application,
                client: FindUser(application.clientLinker),
                status: "reviewed",
              });
            }}
            className="btn-link"
            style={{ cursor: "pointer" }}
          >
            Review
          </span>
        ) : null}
        {application.status === "reviewed" ? (
          <span
            onClick={() => {
              setShowModal(true);
              setType("disburse");
              setApplication({
                ...application,
                client: FindUser(application.clientLinker),
                status: "disbursed",
              });
            }}
            className="btn-link"
            style={{ cursor: "pointer" }}
          >
            Disburse
          </span>
        ) : null}
        <PencilSquare
          onClick={() => {
            setShowModal(true);
            setType("edit");
            setApplication({
              ...application,
              client: FindUser(application.clientLinker),
            });
          }}
          style={{ cursor: "pointer" }}
          className="text-info mx-2"
        />

        <Trash
          onClick={() => {
            setShowModal(true);
            setType("delete");
            setApplication({
              ...application,
              client: FindUser(application.clientLinker),
            });
          }}
          style={{ cursor: "pointer" }}
          className="text-danger mx-2"
        />
      </td>
    </tr>
  );
};

export default ApplicationDisp;
