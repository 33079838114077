import React, { useState, useEffect } from "react";
import ApplicationCrud from "./ApplicationCrud";
import { useDispatch, useSelector } from "react-redux";
import NoApplication from "./NoApplication";
import { FetchApplications } from "../../Network/ApplicationApi";
import { PlainList } from "flatlist-react";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowBarLeft } from "react-bootstrap-icons";
import ClientApplication from "./ClientApplication";
import GeneratePdf from "../../Utils/GeneratePdf";

const ClientApplications = () => {
  useEffect(() => {
    FetchApplications(User.token, ActiveCred.instLinker, dispatch, online);
  }, []);

  //redux dispatch
  const dispatch = useDispatch();
  const params = useParams();
  const Navigate = useNavigate();

  const [ShowModal, setShowModal] = useState(false);
  const [Application, setApplication] = useState({});
  const [Type, setType] = useState("add");
  const User = useSelector((state) => state.auth.user);
  const online = useSelector((state) => state.online.online);
  const ActiveCred = useSelector((state) => state.cred.activeCred);
  const Applications = useSelector((state) => state.application.applications)
    .filter(
      (application) =>
        parseInt(application.deleted) === 0 &&
        parseInt(application.clientLinker) === parseInt(params.clientLinker) &&
        parseInt(application.accountLinker) === parseInt(params.accountLinker)
    )
    .sort((a, b) => parseInt(b.linker) - parseInt(a.linker));
  const Creds = useSelector((state) => state.cred.creds);
  const Accounts = useSelector((state) => state.account.accounts).filter(
    (account) => parseInt(account.deleted) === 0
  );

  const ClientUser = () => {
    let cred = Creds.find(
      (cred) => parseInt(cred.linker) == parseInt(params.clientLinker)
    );

    return cred || { firstname: "unknown" };
  };

  const Account = () => {
    let account = Accounts.find(
      (account) => parseInt(account.linker) === parseInt(params.accountLinker)
    );

    return account || { name: "unknown" };
  };

  return (
    <div>
      {" "}
      <div className="d-flex justify-content-around">
        <div>
          {" "}
          <button
            className="btn btn-sm btn-outline-secondary"
            onClick={() => Navigate(-1)}
          >
            <ArrowBarLeft></ArrowBarLeft> Go Back
          </button>
        </div>
        <div>
          {" "}
          <p className="text-capitalize text-center m-0 p-0 h5">
            {ClientUser().clientReg} {ClientUser().firstname}{" "}
            {ClientUser().lastname} {ClientUser().surname}
          </p>
          <p className="text-capitalize text-center m-0 p-0 h6">
            {Account().name} Applications
          </p>
        </div>
        <button
          className="btn btn-sm btn-outline-secondary"
          onClick={() => {
            setType("add");
            setShowModal(true);
            setApplication({
              linker: Date.now(),
              trace: Date.now(),
              details: "",
              deleted: "0",
              client: ClientUser(),
              clientLinker: params.clientLinker,
              accountLinker: params.accountLinker,
            });
          }}
        >
          Add Application
        </button>
      </div>
      <table className="table table-sm" id={"client-applications"}>
        <thead className="thead-dark">
          <tr>
            <th scope="column">Status</th>
            <th scope="column">Details</th>
            <th scope="column">Amt Applied</th>
            <th scope="column">Amt Approved</th>
            <th scope="column">Amt Disbursed</th>
            <th scope="column">Disburse Date</th>

            <th scope="column">Date</th>
            <th scope="column">By</th>
          </tr>
        </thead>
        {Applications.length > 0 ? (
          <tbody>
            <PlainList
              list={Applications}
              renderOnScroll
              renderItem={(application) => (
                <ClientApplication
                  setShowModal={setShowModal}
                  ShowModal={ShowModal}
                  application={application}
                  setApplication={setApplication}
                  type={Type}
                  setType={setType}
                  key={application.linker}
                ></ClientApplication>
              )}
            />
          </tbody>
        ) : (
          <NoApplication></NoApplication>
        )}
      </table>
      <GeneratePdf
        id={"client-applications"}
        name={"client-applications"}
      ></GeneratePdf>
      <ApplicationCrud
        setShowModal={setShowModal}
        ShowModal={ShowModal}
        application={Application}
        setApplication={setApplication}
        type={Type}
        from={"client"}
      ></ApplicationCrud>
    </div>
  );
};

export default ClientApplications;
