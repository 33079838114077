import React, { useState } from "react";
import { ArrowBarLeft } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import MpesaTesting from "./MpesaTesting";
import Details from "./Details";
import { useSelector } from "react-redux";

const MpesaOnline = () => {
	const ActiveCred = useSelector((state) => state.cred.activeCred);
	const mpesaOnline = useSelector((state) => state.onlinePay.onlinePays).find(
		(mpesaOnline) =>
			parseInt(mpesaOnline.instLinker) === parseInt(ActiveCred.instLinker)
	);

	const navigate = useNavigate();
	const [Type, setType] = useState("details");
	return (
		<div>
			<div className="d-flex justify-content-around mt-1">
				<p className="h6 text-center text-decoration-underline">
					M-Pesa Online Pay For Your Business
				</p>{" "}
			</div>{" "}
			<div className="d-flex justify-content-around">
				{" "}
				<button
					onClick={() => navigate(-1)}
					className="btn btn-outline-secondary rounded m-1"
				>
					<ArrowBarLeft /> Go Back
				</button>
				<button
					onClick={() => setType("details")}
					className={`btn rounded m-1 ${
						Type === "details" ? "btn-success" : "btn-outline-success"
					}`}
				>
					Details
				</button>
				<button
					onClick={() => setType("live-test")}
					className={`btn rounded m-1 ${
						Type === "live-test" ? "btn-success" : "btn-outline-success"
					}`}
				>
					Connect Live
				</button>
			</div>
			{Type === "details" ? (
				<Details></Details>
			) : (
				<MpesaTesting type={Type} mpesaOnline={mpesaOnline}></MpesaTesting>
			)}
		</div>
	);
};

export default MpesaOnline;
