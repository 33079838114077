import React, { useState, useEffect } from "react";
import { Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import GeneratePdf from "../../../Utils/GeneratePdf";
import { Save, Save2 } from "react-bootstrap-icons";
import { ApiUrl } from "../../../Network/Urls";
import { fetchEntrys } from "../../../reducers/EntrySlice";
import swal from "sweetalert";

const SaveModal = ({
	Group,
	Account,
	Dividends,
	ShowModal,
	setShowModal,
	FromDate,
	ToDate,
	setClientsDividends,
}) => {
	//redux dispatch
	const Entries = useSelector((state) => state.entry.entrys).filter(
		(entry) => parseInt(entry.deleted) === 0
	);
	const dispatch = useDispatch();
	const Groups = useSelector((state) => state.group.groups).filter(
		(group) => parseInt(group.deleted) === 0
	);
	const Accounts = useSelector((state) => state.account.accounts).filter(
		(account) => parseInt(account.deleted) === 0
	);
	const inst = useSelector((state) => state.inst.activeInst);
	const User = useSelector((state) => state.auth.user);

	const UpdateDividend = (value, index) => {
		let NewDividends = [...Dividends];
		NewDividends[index].TotalClientDivs = value;
		setClientsDividends(NewDividends);
	};
	const [Loading, setLoading] = useState({ status: false, indexes: [] });

	const SaveDividends = (inputs) => {
		let trace = Date.now();
		setLoading({
			status: inputs.length > 1 ? true : false,
			indexes: [...Loading.indexes, inputs[0].linker],
		});
		try {
			swal({
				title: "Dividends Will Be Saved!!",
				text: `Saving Will Reduce ${GroupName() || ""} Banking And Interest`,
				icon: "warning",
				showCancelButton: true,
				buttons: ["Cancel Save", "Continue"],
			}).then(async (isConfirmed) => {
				if (isConfirmed) {
					// Map over inputs and create a fetch promise for each input
					const requests = inputs
						.filter(
							(input) =>
								!Entries.some(
									(entry) =>
										parseInt(entry.clientLinker) === parseInt(input.linker) &&
										entry.accountLinker === `${FromDate}-${ToDate}`
								)
						)
						.map(
							(input, index) =>
								fetch(`${ApiUrl}/entry/add`, {
									method: "POST",
									headers: {
										"content-type": "application/json",
										authorization: `bearer ${User.token}`,
									},
									body: JSON.stringify({
										amount: parseInt(input.TotalClientDivs),
										type: "dividend",
										accountLinker: `${FromDate}->${ToDate}`,
										instLinker: inst.linker,
										clientLinker: input.linker,
										linker: trace + index,
										trace: trace + index,
										details: JSON.stringify(input.clientDivs),
									}),
								})
									.then((res) => res.json())
									.catch((err) => ({ err })) // Parse JSON from each response
						);

					// Use Promise.all to wait for all requests to complete
					let Responses = await Promise.all(requests);
					let successResponses = Responses.filter(
						(response) => response.status === 201
					).map((response) => response.entry);
					dispatch(fetchEntrys([...successResponses, ...Entries]));
				} else {
				}
			});
		} catch (error) {
		} finally {
			setLoading({
				status: false,
				indexes: Loading.indexes.filter((index) => index !== inputs[0].linker),
			});
			setShowModal(false);
		}
	};

	const HandleSubmit = (e) => {
		e.preventDefault();
		SaveDividends(Dividends);
	};

	const GroupName = () =>
		Groups.find((group) => parseInt(group.linker) === parseInt(Group))?.name;

	return (
		<div>
			<Modal
				show={ShowModal}
				onHide={() => setShowModal(false)}
				backdrop="static"
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title></Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form onSubmit={HandleSubmit}>
						<div id="dividend-calculate">
							<p className="h6 text-center text-decoration-underline">
								<strong className="text-capitalize">{inst.name}</strong>
								<br />
								Dividend For {GroupName()} From {FromDate} To {ToDate} Based On{" "}
								{
									Accounts.find(
										(account) => parseInt(account.linker) === parseInt(Account)
									)?.name
								}
							</p>
							<table className="table table-sm table-responsive">
								<thead>
									<tr>
										<th>AC No</th>
										<th>Name</th>
										<th>Dividend</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									{Dividends.map((client, index) => (
										<tr key={client.linker}>
											<td>{client.clientReg}</td>
											<td>
												{client.firstname} {client.lastname}
											</td>
											<td>
												<input
													className="form-control p-0 m-0"
													value={parseInt(client.TotalClientDivs)}
													placeholder="enter dividend"
													onChange={(e) =>
														UpdateDividend(e.target.value, index)
													}
												/>
											</td>
											<th>
												{Loading.indexes.find(
													(specific) => specific === client.linker
												) || Loading.status === true ? (
													<span className="text-success spinner-border"></span>
												) : Entries.find(
														(entry) =>
															parseInt(entry.clientLinker) ===
																parseInt(client.linker) &&
															entry.accountLinker === `${FromDate}->${ToDate}`
												  ) ? null : (
													<Save2
														className="text-success m-0 p-0"
														style={{ cursor: "pointer" }}
														onClick={() => SaveDividends([client])}
													/>
												)}
											</th>
										</tr>
									))}
									<tr>
										<th colSpan={2}>Total</th>
										<th>
											{parseInt(
												Dividends.reduce(
													(sum, client) => sum + +client.TotalClientDivs,
													0
												)
											)}
										</th>
									</tr>
								</tbody>
							</table>
						</div>
						<div className="d-flex justify-content-around mb-2">
							<GeneratePdf
								id={"dividend-calculate"}
								name={`${
									Groups.find(
										(group) => parseInt(group.linker) === parseInt(Group)
									)?.name || "All-Groups"
								}-${FromDate}->${ToDate}-dividend`}
							></GeneratePdf>
							{Loading.indexes.length > 0 || Loading.status === true ? (
								<span className="text-success spinner-border"></span>
							) : (
								<button type="submit" className="btn btn-success rounded">
									<Save /> Save All
								</button>
							)}
						</div>
					</Form>
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default SaveModal;
