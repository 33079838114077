import React from "react";

const NoExpense = () => {
	return (
		<tbody>
			<tr>
				<th colSpan={2}>No Group Expenses</th>

				<td colSpan={2}>
					Group Expenses here can be used to denote cashoutflows from a group,
					They can be service or management fee paid to an organization,
					dividends paid to clients or any other expense
				</td>
			</tr>
		</tbody>
	);
};

export default NoExpense;
