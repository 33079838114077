import React, { useState, useEffect } from "react";
import ApplicationCrud from "./ApplicationCrud";
import { useDispatch, useSelector } from "react-redux";
import NoApplication from "./NoApplication";
import { fetchCreds } from "../../reducers/CredSlice";
import ApplicationDisp from "./ApplicationDisp";
import { FetchApplications } from "../../Network/ApplicationApi";
import { PlainList } from "flatlist-react";
import GeneratePdf from "../../Utils/GeneratePdf";

const Applications = () => {
	useEffect(() => {
		FetchApplications(User.token, ActiveCred.instLinker, dispatch, online);
	}, []);

	const [ShowModal, setShowModal] = useState(false);
	const [Application, setApplication] = useState({});
	const [Type, setType] = useState("add");
	const [By, setBy] = useState("all");
	const [Day, setDay] = useState("all");
	const [Month, setMonth] = useState("all");
	const [Year, setYear] = useState("all");
	const [DisDay, setDisDay] = useState("all");
	const [DisMonth, setDisMonth] = useState("all");
	const [DisYear, setDisYear] = useState("all");

	//redux dispatch
	const dispatch = useDispatch();

	const User = useSelector((state) => state.auth.user);
	const online = useSelector((state) => state.online.online);
	const ActiveCred = useSelector((state) => state.cred.activeCred);
	const Applications = useSelector((state) => state.application.applications);
	const Creds = useSelector((state) => state.cred.creds);
	const Groups = useSelector((state) => state.group.groups).filter(
		(group) => parseInt(group.deleted) === 0
	);
	const Branches = useSelector((state) => state.branch.branchs).filter(
		(branch) => parseInt(branch.deleted) === 0
	);
	const [Group, setGroup] = useState("all");
	const [Account, setAccount] = useState("all");
	const [Branch, setBranch] = useState("all");
	const [Status, setStatus] = useState("all");
	const [Amount, setAmount] = useState("appliedAmount");

	const Accounts = useSelector((state) => state.account.accounts).filter(
		(account) => parseInt(account.deleted) === 0
	);

	const FindUser = (linker) => {
		let cred = Creds.find((cred) => parseInt(cred.linker) == parseInt(linker));

		return cred || { email: "unknown" };
	};

	const Clients = useSelector((state) => state.cred.creds).filter(
		(client) =>
			parseInt(client.deleted) === 0 &&
			parseInt(client.client) === 1 &&
			(parseInt(client.groupLinker) === parseInt(Group) || Group === "all")
	);

	const SearchApplication = Applications.filter(
		(application) =>
			parseInt(application.deleted) === 0 &&
			parseInt(application.instLinker) === parseInt(ActiveCred.instLinker) &&
			(parseInt(application.branchLinker) === parseInt(Branch) ||
				Branch === "all") &&
			(parseInt(application.accountLinker) === parseInt(Account) ||
				Account === "all") &&
			Clients.some(
				(client) =>
					parseInt(client.linker) === parseInt(application.clientLinker)
			) &&
			(application.status === Status || Status === "all") &&
			(parseInt(
				new Date(parseInt(application.linker))
					.toLocaleDateString()
					.split("/")[2]
			) === parseInt(Year) ||
				Year === "all") &&
			(parseInt(
				new Date(parseInt(application.linker))
					.toLocaleDateString()
					.split("/")[0]
			) === parseInt(Month) ||
				Year === "all" ||
				Month === "all") &&
			(parseInt(
				new Date(parseInt(application.linker))
					.toLocaleDateString()
					.split("/")[1]
			) === parseInt(Day) ||
				Year === "all" ||
				Month === "all" ||
				Day == "all") &&
			(parseInt(application.disburseDate?.split("-")[0]) ===
				parseInt(DisYear) ||
				DisYear === "all") &&
			(parseInt(application.disburseDate?.split("-")[1]) ===
				parseInt(DisMonth) ||
				DisYear === "all" ||
				DisMonth === "all") &&
			(parseInt(application.disburseDate?.split("-")[2]) === parseInt(DisDay) ||
				DisYear === "all" ||
				DisMonth === "all" ||
				DisDay == "all") &&
			(parseInt(application.credLinker) === parseInt(By) || By === "all")
	).sort((a, b) => parseInt(b.linker) - parseInt(a.linker));

	return (
		<div>
			<div className="d-flex justify-content-around mt-2">
				<p className="text-center h6 text-primary text-capitalize">
					{SearchApplication.length} Applications
				</p>{" "}
				<button
					className="btn btn-sm btn-outline-secondary"
					onClick={() => {
						setType("add");
						setShowModal(true);
						setApplication({
							linker: Date.now(),
							trace: Date.now(),
							details: "",
							deleted: "0",
						});
					}}
				>
					Add Application
				</button>
			</div>

			<table className="table table-sm table-responsive" id="applications">
				<thead className="thead-dark">
					<tr>
						<th scope="colum">Client</th>
						{Accounts.length > 0 ? <th scope="column">Account</th> : null}
						{Groups.length > 0 ? <th scope="column">Group</th> : null}
						{Branches.length > 0 ? <th scope="column">Branch</th> : null}
						<th scope="column">Status</th>
						<th scope="column">Details</th>
						<th scope="column">
							<select
								className="form-control rounded"
								value={Amount}
								onChange={(e) => setAmount(e.target.value)}
							>
								<option value={"appliedAmount"}>Amt Applied</option>
								<option value={"approvedAmount"}>Amt Approved</option>
								<option value={"disbursedAmount"}>Amt Disbursed</option>
							</select>
						</th>
						<th scope="column">Disburse Date</th>

						<th scope="column">Date</th>
						<th scope="column">By</th>
					</tr>
					<tr>
						<th scope="column"></th>
						{Accounts.length > 0 ? (
							<th scope="column">
								<select
									className="form-control rounded"
									value={Account}
									onChange={(e) => setAccount(e.target.value)}
								>
									<option value={"all"}>All Accounts</option>
									{Accounts.map((account, index) => (
										<option key={index} value={account.linker}>
											{account.name}
										</option>
									))}
								</select>{" "}
							</th>
						) : null}
						{Groups.length > 0 ? (
							<th scope="column">
								<select
									className="form-control rounded"
									value={Group}
									onChange={(e) => setGroup(e.target.value)}
								>
									<option value={"all"}>All Groups</option>
									{Groups.map((group, index) => (
										<option key={index} value={group.linker}>
											{group.name}
										</option>
									))}
								</select>{" "}
							</th>
						) : null}
						{Branches.length > 0 ? (
							<th scope="column">
								<select
									className="form-control rounded"
									value={Branch}
									onChange={(e) => setBranch(e.target.value)}
								>
									<option value={"all"}>All Branches</option>
									{Branches.map((branch, index) => (
										<option key={index} value={branch.linker}>
											{branch.name}
										</option>
									))}
								</select>{" "}
							</th>
						) : null}
						<th scope="column">
							<select
								className="form-control rounded"
								value={Status}
								onChange={(e) => setStatus(e.target.value)}
							>
								<option value={"all"}>All Status</option>
								<option value={"inReview"}>In Review</option>
								<option value={"reviewed"}>Reviewed</option>
								<option value={"disbursed"}>Disbursed</option>
							</select>{" "}
						</th>

						<th scope="column"></th>
						<th>{SearchApplication.reduce((a, b) => +a + +b[Amount], 0)}</th>
						<th>
							<div className="d-flex">
								<select
									className="form-control rounded"
									value={DisYear}
									onChange={(e) => setDisYear(e.target.value)}
								>
									<option value={"all"}>All Yrs</option>
									{[
										...new Set(
											Applications.map((application) =>
												parseInt(application.disburseDate?.split("-")[0])
											)
										),
									].map((yr) => (
										<option key={yr} value={yr}>
											{yr}
										</option>
									))}
								</select>
								{DisYear !== "all" ? (
									<select
										className="form-control rounded"
										value={DisMonth}
										onChange={(e) => setDisMonth(e.target.value)}
									>
										<option value={"all"}>All Mths</option>
										{[
											...new Set(
												Applications.map((application) =>
													parseInt(application.disburseDate?.split("-")[1])
												)
											),
										].map((mth) => (
											<option key={mth} value={mth}>
												{mth}
											</option>
										))}
									</select>
								) : null}
								{DisMonth !== "all" && DisYear !== "all" ? (
									<select
										className="form-control rounded"
										value={DisDay}
										onChange={(e) => setDisDay(e.target.value)}
									>
										<option value={"all"}>All Dates</option>
										{[
											...new Set(
												Applications.map((application) =>
													parseInt(application.disburseDate?.split("-")[2])
												)
											),
										].map((day) => (
											<option key={day} value={day}>
												{day}
											</option>
										))}
									</select>
								) : null}
							</div>
						</th>
						<th>
							<div className="d-flex">
								<select
									className="form-control rounded"
									value={Year}
									onChange={(e) => setYear(e.target.value)}
								>
									<option value={"all"}>All Yrs</option>
									{[
										...new Set(
											Applications.map(
												(application) =>
													new Date(parseInt(application.linker))
														.toLocaleDateString()
														.split("/")[2]
											)
										),
									].map((yr) => (
										<option key={yr} value={yr}>
											{yr}
										</option>
									))}
								</select>
								{Year !== "all" ? (
									<select
										className="form-control rounded"
										value={Month}
										onChange={(e) => setMonth(e.target.value)}
									>
										<option value={"all"}>All Mths</option>
										{[
											...new Set(
												Applications.map(
													(application) =>
														new Date(parseInt(application.linker))
															.toLocaleDateString()
															.split("/")[0]
												)
											),
										].map((mth) => (
											<option key={mth} value={mth}>
												{mth}
											</option>
										))}
									</select>
								) : null}
								{Month !== "all" && Year !== "all" ? (
									<select
										className="form-control rounded"
										value={Day}
										onChange={(e) => setDay(e.target.value)}
									>
										<option value={"all"}>All Dates</option>
										{[
											...new Set(
												Applications.map(
													(application) =>
														new Date(parseInt(application.linker))
															.toLocaleDateString()
															.split("/")[1]
												)
											),
										].map((day) => (
											<option key={day} value={day}>
												{day}
											</option>
										))}
									</select>
								) : null}
							</div>
						</th>
						<th>
							<select
								className="form-control rounded"
								value={By}
								onChange={(e) => setBy(e.target.value)}
							>
								<option value={"all"}>All Staffs</option>
								<PlainList
									list={[
										...new Set(
											Applications.map((application) => application.credLinker)
										),
									]}
									renderOnScroll
									renderItem={(staff) => (
										<option value={staff} key={staff}>
											{FindUser(staff).email}
										</option>
									)}
								/>
							</select>
						</th>
					</tr>
				</thead>
				{SearchApplication.length > 0 ? (
					<tbody>
						<PlainList
							list={SearchApplication}
							renderOnScroll
							renderItem={(application) => (
								<ApplicationDisp
									setShowModal={setShowModal}
									ShowModal={ShowModal}
									application={application}
									setApplication={setApplication}
									type={Type}
									setType={setType}
									key={application.linker}
									Amount={Amount}
								></ApplicationDisp>
							)}
						/>
					</tbody>
				) : (
					<NoApplication></NoApplication>
				)}
			</table>
			<GeneratePdf id={"applications"} name={"applications"}></GeneratePdf>
			<ApplicationCrud
				setShowModal={setShowModal}
				ShowModal={ShowModal}
				application={Application}
				setApplication={setApplication}
				type={Type}
			></ApplicationCrud>
		</div>
	);
};

export default Applications;
