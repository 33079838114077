import React, { useEffect, useState } from "react";
import { FetchClientApplications } from "../../Network/ClientApi";
import { useDispatch, useSelector } from "react-redux";
import { PencilSquare } from "react-bootstrap-icons";
import ApplicationCrud from "./ApplicationCrud";

const MyApplications = () => {
  useEffect(() => {
    FetchClientApplications(
      User.token,
      ActiveCred.instLinker,
      dispatch,
      online
    );
  }, []);

  //redux dispatch
  const dispatch = useDispatch();

  const [Application, setApplication] = useState({});
  const [ShowModal, setShowModal] = useState(false);
  const [Type, setType] = useState("add");
  const [Loading, setLoading] = useState(false);

  const User = useSelector((state) => state.auth.user);
  const online = useSelector((state) => state.online.online);
  const ActiveCred = useSelector((state) => state.cred.activeCred);
  const Accounts = useSelector((state) => state.account.accounts).filter(
    (account) => parseInt(account.deleted) === 0
  );
  const Applications = useSelector(
    (state) => state.application.applications
  ).filter(
    (application) =>
      parseInt(application.deleted) === 0 &&
      parseInt(application.clientLinker) === parseInt(ActiveCred.linker)
  );

  const FindAccount = (linker) =>
    Accounts.find((account) => parseInt(account.linker) === parseInt(linker));

  return (
    <div>
      <table className="table table-sm table-responsive">
        <thead>
          <tr>
            <th>A/c</th>
            <th>Status</th>
            <th>Details</th>
            <th>Applied</th>
            <th>Approved</th>
            <th>Disbursed</th>
            <th>Disbursed Date</th>
            <th>Date</th>
            <th>
              {" "}
              <button
                className="btn btn-sm btn-outline-primary"
                onClick={() => {
                  setLoading(false);
                  setType("add");
                  setShowModal(true);
                  setApplication({});
                }}
              >
                Apply
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          {Applications.sort(
            (a, b) => parseInt(b.linker) - parseInt(a.linker)
          ).map((application) => (
            <tr key={application.linker}>
              <td>{FindAccount(application.accountLinker).name}</td>
              <td>{application.status}</td>
              <td>{application.details}</td>
              <td>{application.appliedAmount}</td>
              <td>{application.approvedAmount}</td>
              <td>{application.disbursedAmount}</td>
              <td>{application.disburseDate}</td>
              <td>
                {new Date(parseInt(application.linker)).toLocaleDateString(
                  "en-US"
                )}
              </td>
              <td>
                {application.status === "inReview" ? (
                  <PencilSquare
                    onClick={() => {
                      setShowModal(true);
                      setType("edit");
                      setApplication({ ...application });
                      setLoading(false);
                    }}
                    style={{ cursor: "pointer" }}
                    className="text-info mx-2"
                  />
                ) : null}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ApplicationCrud
        setShowModal={setShowModal}
        ShowModal={ShowModal}
        application={Application}
        setApplication={setApplication}
        type={Type}
        setLoading={setLoading}
        Loading={Loading}
      ></ApplicationCrud>
    </div>
  );
};

export default MyApplications;
