import React from "react";
import { useParams } from "react-router-dom";

const NoBranch = () => {
  const params = useParams();
  return (
    <tbody>
      <tr>
        <td>No Branches</td>

        <td>
          Institution branches help in categorising staffs and transactions to
          know where they took place
        </td>
      </tr>
    </tbody>
  );
};

export default NoBranch;
