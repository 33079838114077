import React, { useState, useEffect } from "react";
import { Form, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

const DividendModal = ({
	ShowModal,
	setShowModal,
	Account,
	setAccount,
	Data,
	setData,
	setFromDate,
	FromDate,
	ToDate,
	setToDate,
	Group,
	setGroup,
	Entries,
	Clients,
	CalculateDividends,
	setShowSaveModal,
}) => {
	const [FromYear, FromMonth] = FromDate.split("-").map(Number);
	const [ToYear, ToMonth] = ToDate.split("-").map(Number);
	const [DivRate, setDivRate] = useState(0);
	const firstDayUnix = new Date(FromYear, FromMonth - 1, 1).getTime();
	const lastDayUnix = new Date(ToYear, ToMonth, 0).getTime();

	useEffect(() => {
		setData(getMonthsBetween(firstDayUnix, lastDayUnix));
	}, [ShowModal, Group, DivRate, firstDayUnix, lastDayUnix]);

	const Accounts = useSelector((state) => state.account.accounts).filter(
		(account) => parseInt(account.deleted) === 0
	);
	const Groups = useSelector((state) => state.group.groups).filter(
		(group) => parseInt(group.deleted) === 0
	);
	const Expenses = useSelector((state) => state.expense.expenses).filter(
		(expense) =>
			(expense.catLinker === "service-fee" || expense.catLinker === "other") &&
			(Group === "all" || parseInt(expense.brachLinker) === parseInt(Group))
	);

	const HandleSubmit = (e) => {
		e.preventDefault();
		CalculateDividends();
		setShowSaveModal(true);
		setShowModal(false);
	};

	const groupEntries = Entries.filter((entry) =>
		Clients.some(
			(client) => parseInt(client.linker) === parseInt(entry.clientLinker)
		)
	);

	const getMonthsBetween = (startTimestamp, endTimestamp) => {
		const startDate = new Date(startTimestamp);
		const endDate = new Date(endTimestamp);

		return Array.from(
			{
				length:
					(endDate.getFullYear() - startDate.getFullYear()) * 12 +
					(endDate.getMonth() - startDate.getMonth()) +
					1,
			},
			(_, i) => {
				const year = startDate.getFullYear();
				const month = startDate.getMonth() + i;

				const firstDay = new Date(year, month, 1).getTime();
				const lastDay = new Date(year, month + 1, 0, 23, 59, 59, 999).getTime();

				const int =
					groupEntries
						.filter(
							(entry) =>
								entry.linker >= firstDay &&
								entry.linker <= lastDay &&
								entry.type === "loan-interest"
						)
						.reduce((sum, entry) => sum + +entry.amount, 0) -
					groupEntries
						.filter(
							(entry) =>
								entry.linker >= firstDay &&
								entry.linker <= lastDay &&
								entry.type === "dividend"
						)
						.reduce((sum, entry) => sum + +entry.amount, 0) -
					Expenses.filter(
						(expense) => expense.linker >= firstDay && expense.linker <= lastDay
					).reduce((sum, expense) => sum + +expense.amount, 0);

				return {
					year: new Date(firstDay).getFullYear(),
					month: new Date(firstDay).getMonth() + 1,
					firstDay,
					lastDay,
					int,
					div: DivRate > 0 ? parseInt((int * DivRate) / 100) : 0,
				};
			}
		);
	};

	const UpdateData = (value, index) => {
		let newData = [...Data];
		newData[index] = { ...newData[index], div: value };
		setData([...newData]);
	};

	//console.log(getMonthsBetween(firstDayUnix, lastDayUnix));
	//console.log(Data);
	return (
		<Modal
			show={ShowModal}
			onHide={() => setShowModal(false)}
			backdrop="static"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>
					<p className="text-capitalize text-center">
						Add Dividend Sharing Inputs
					</p>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form onSubmit={HandleSubmit}>
					<div className="form-group">
						<label className="mb-2 text-capitalize">
							Account To Be Used For Sharing
						</label>
						<select
							className="rounded form-control"
							value={Account}
							onChange={(e) => setAccount(e.target.value)}
							required
						>
							<option value={""}>Select Account</option>
							{Accounts.map((account) => (
								<option value={account.linker}>{account.name}</option>
							))}
						</select>
						<hr />
					</div>
					{Groups.length > 0 ? (
						<div className="form-group">
							<label className="mb-2 text-capitalize">
								Select Group To Calculate
							</label>
							<select
								className="rounded form-control"
								value={Group}
								onChange={(e) => setGroup(e.target.value)}
								required
							>
								<option value={"all"}>All Groups</option>
								{Groups.map((group) => (
									<option value={group.linker}>{group.name}</option>
								))}
							</select>
							<hr />
						</div>
					) : null}
					<div className="form-group">
						<label className="mb-2 text-capitalize">Select From Month</label>
						<input
							type="month"
							onClick={(e) => e.target.showPicker()}
							className="rounded form-control"
							value={FromDate}
							onChange={(e) => setFromDate(e.target.value)}
						/>

						<hr />
					</div>
					<div className="form-group">
						<label className="mb-2 text-capitalize">Select End Month</label>

						<input
							type="month"
							onClick={(e) => e.target.showPicker()}
							className="rounded form-control"
							value={ToDate}
							onChange={(e) => setToDate(e.target.value)}
							min={FromDate}
						/>
						<hr />
					</div>
					<table className="table table-striped table-sm table-responsive">
						<thead>
							<tr>
								<th>Mth</th>
								<th>Int</th>
								<th className="text-center text-wrap">
									Enter Div Rate/Input Manually Per Month
								</th>
							</tr>
							<tr>
								<th></th>
								<th></th>
								<th>
									<input
										type="number"
										min={0}
										max={100}
										placeholder="Enter Div Rate"
										className="form-control"
										value={DivRate}
										onChange={(e) => {
											setDivRate(e.target.value);
										}}
									/>
								</th>
							</tr>
						</thead>
						<tbody>
							{Data.map((entry, index) => (
								<tr>
									<td>{`${entry.month}/${entry.year}`}</td>
									<td>{entry.int}</td>
									<td>
										<input
											type="number"
											placeholder="Enter Div"
											className="form-control"
											value={entry.div}
											onChange={(e) => UpdateData(e.target.value, index)}
										/>
									</td>
								</tr>
							))}

							<tr>
								<th>Total</th>
								<th>{Data.reduce((sum, entry) => sum + +entry.int, 0)}</th>
								<th>{Data.reduce((sum, entry) => sum + +entry.div, 0)}</th>
							</tr>
						</tbody>
					</table>
					<div className="d-flex justify-content-around mb-2">
						<button type="submit" className="btn btn-success rounded-pill">
							Calculate
						</button>
					</div>
				</Form>
			</Modal.Body>
		</Modal>
	);
};

export default DividendModal;
