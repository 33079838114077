import React from "react";
import { useSelector } from "react-redux";

const MpesaPayment = ({ onlinePayment }) => {
	const Payments = useSelector((state) => state.entry.entrys).filter(
		(payment) =>
			payment.code.toLowerCase() === onlinePayment.transId.toLowerCase() &&
			parseInt(payment.deleted) === 0
	);
	return (
		<>
			<tr>
				<th scope="row">{onlinePayment.transRef}</th>
				<td>{onlinePayment.transSender}</td>
				<td>{onlinePayment.transContact}</td>
				<td>{onlinePayment.transId}</td>
				<td>
					{new Date(parseInt(onlinePayment.linker)).toLocaleDateString("en-US")}
				</td>
				<td>{onlinePayment.transAmount}</td>
				<td>{Payments.reduce((a, b) => +a + +b.amount, 0)}</td>
				<td>
					{onlinePayment.transAmount -
						Payments.reduce((a, b) => +a + +b.amount, 0)}
				</td>
			</tr>
		</>
	);
};

export default MpesaPayment;
