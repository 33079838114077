import { createSlice } from "@reduxjs/toolkit";

export const GroupSlice = createSlice({
  name: "group",
  initialState: {
    groups: [],
  },
  reducers: {
    fetchGroups: (state, action) => {
      state.groups = action.payload;
    },
    addGroup: (state, action) => {
      state.groups = [action.payload, ...state.groups];
    },
    updateGroup: (state, action) => {
      let index = state.groups.findIndex(
        (group) => parseInt(group.linker) === parseInt(action.payload.linker)
      );

      let prevGroups = [...state.groups];

      prevGroups.splice(
        index < 0 ? 1 : index,
        index < 0 ? 0 : 1,
        action.payload
      );

      state.groups = prevGroups;
    },
    deleteGroup: (state, action) => {
      let newGroups = state.groups.filter(
        (group) => parseInt(group.linker) !== parseInt(action.payload.linker)
      );
      state.groups = newGroups;
    },
  },
});

export const { fetchGroups, addGroup, updateGroup, deleteGroup } =
  GroupSlice.actions;

export default GroupSlice.reducer;
