import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Calculator, Envelope, People, PersonAdd } from "react-bootstrap-icons";
import ClientCrud from "./ClientCrud";
import { useDispatch, useSelector } from "react-redux";
import { FetchClients } from "../../Network/CredApi";
import { PlainList } from "flatlist-react";
import GeneratePdf from "../../Utils/GeneratePdf";
import ClientDisp from "./ClientDisp";
import ClientEntry from "./ClientEntry";
import SingleSms from "../Admin/Sms/SingleSms";
import { useNavigate } from "react-router-dom";
import { addLiveEntry } from "../../Network/EntryApi";
const Clients = () => {
	const online = useSelector((state) => state.online.online);
	useEffect(() => {
		FetchClients(ActiveCred.instLinker, User.token, dispatch, online);
		LoanIntAutoCalc();
		GetMonthTimestamps(Month);
	}, [online]);

	const GetCurrentWeek = () => {
		const currentDate = new Date();
		const dayOfWeek = currentDate.getDay() || 7; // Set Sunday as 7 for ISO week
		currentDate.setDate(currentDate.getDate() + 4 - dayOfWeek); // Shift date to Thursday in the current week
		const yearStart = new Date(currentDate.getFullYear(), 0, 1);
		const weekNumber = Math.ceil(
			((currentDate - yearStart) / 86400000 + 1) / 7
		);

		return `${currentDate.getFullYear()}-W${weekNumber
			.toString()
			.padStart(2, "0")}`;
	};

	const GetCurrentMonth = () => {
		const currentDate = new Date();
		const year = currentDate.getFullYear();
		const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed
		return `${year}-${month}`;
	};

	const GetWeekTimestamps = (weekString) => {
		const [year, week] = weekString.split("-W").map(Number);

		// Get the first day of the year
		const firstDayOfYear = new Date(year, 0, 1);

		// Calculate the day of the week for the first day of the year (0 = Sunday, 6 = Saturday)
		const dayOfWeek = firstDayOfYear.getDay() || 7; // Adjust so Sunday is 7

		// Get the first Monday of the year
		const dayOffset = dayOfWeek <= 4 ? dayOfWeek - 1 : dayOfWeek - 8;
		const firstMonday = new Date(
			firstDayOfYear.setDate(firstDayOfYear.getDate() - dayOffset)
		);

		// Calculate the start of the given week
		const weekStart = new Date(
			firstMonday.setDate(firstMonday.getDate() + (week - 1) * 7)
		);

		// Calculate the end of the week (Sunday)
		const weekEnd = new Date(weekStart);
		weekEnd.setDate(weekStart.getDate() + 6); // Move to Sunday

		// Convert to Unix timestamps in milliseconds
		const startTimestamp = weekStart.getTime();
		const endTimestamp = weekEnd.getTime() + 24 * 60 * 60 * 1000 - 1; // End of the day on Sunday
		setStartLinker(startTimestamp);
		setEndLinker(endTimestamp);
		return { startTimestamp, endTimestamp };
	};

	const GetMonthTimestamps = (monthString) => {
		const [year, month] = monthString.split("-").map(Number);

		// Get the first day of the month (month is 0-indexed)
		const firstDayOfMonth = new Date(year, month - 1, 1);

		// Get the first day of the next month
		const firstDayOfNextMonth = new Date(year, month, 1);

		// The end of the month is the last millisecond of the previous day of the next month
		const lastDayOfMonth = new Date(firstDayOfNextMonth.getTime() - 1);

		// Convert to Unix timestamps in milliseconds
		const startTimestamp = firstDayOfMonth.getTime(); // Start of the month
		const endTimestamp = lastDayOfMonth.getTime(); // End of the month (last millisecond)
		setStartLinker(startTimestamp);
		setEndLinker(endTimestamp);
		return { startTimestamp, endTimestamp };
	};

	const GetYearTimestamps = (yearString) => {
		const year = Number(yearString);

		// Get the first day of the year (January 1st, 00:00)
		const firstDayOfYear = new Date(year, 0, 1);

		// Get the first day of the next year
		const firstDayOfNextYear = new Date(year + 1, 0, 1);

		// The end of the year is the last millisecond of December 31st
		const lastDayOfYear = new Date(firstDayOfNextYear.getTime() - 1);

		// Convert to Unix timestamps in milliseconds
		const startTimestamp = firstDayOfYear.getTime(); // Start of the year
		const endTimestamp = lastDayOfYear.getTime(); // End of the year (last millisecond)

		setStartLinker(startTimestamp);
		setEndLinker(endTimestamp);

		return { startTimestamp, endTimestamp };
	};

	//redux dispatch
	const dispatch = useDispatch();
	const Navigate = useNavigate();
	const User = useSelector((state) => state.auth.user);

	const ActiveCred = useSelector((state) => state.cred.activeCred);
	const inst = useSelector((state) => state.inst.activeInst);
	const Groups = useSelector((state) => state.group.groups).filter(
		(group) => parseInt(group.deleted) === 0
	);
	const [Group, setGroup] = useState("all");
	const [AccountView, setAccountView] = useState("all");
	const AllAccounts = useSelector((state) => state.account.accounts).filter(
		(account) => parseInt(account.deleted) === 0
	);
	const Accounts = useMemo(
		() =>
			AllAccounts.filter(
				(account) =>
					AccountView === "all" ||
					parseInt(AccountView) === parseInt(account.linker)
			),
		[AllAccounts, AccountView]
	);

	const LoanAccounts = useMemo(
		() => Accounts.filter((account) => account.status === "loan"),
		[Accounts, AllAccounts, AccountView]
	);
	const SavingAccounts = useMemo(
		() => Accounts.filter((account) => account.status === "saving"),
		[Accounts, AllAccounts, AccountView]
	);
	const Expenses = useSelector((state) => state.expense.expenses);
	const Entrys = useSelector((state) => state.entry.entrys).filter(
		(entry) => parseInt(entry.deleted) === 0
	);

	const [Search, setSearch] = useState("");
	const [ShowModal, setShowModal] = useState(false);
	const [SmsModal, setSmsModal] = useState(false);
	const [Client, setClient] = useState({
		firstname: "",
		lastname: "",
		surname: "",
		clientReg: "",
		contact: "",
		email: "",
	});
	const [Account, setAccount] = useState({
		name: "",
		details: "",
	});
	const [Type, setType] = useState("add");
	const [Entry, setEntry] = useState({});
	const [EntryModal, setEntryModal] = useState(false);
	const [Summary, setSummary] = useState("current");

	const [StartLinker, setStartLinker] = useState("all");
	const [EndLinker, setEndLinker] = useState("all");
	const [Week, setWeek] = useState(GetCurrentWeek());
	const [Month, setMonth] = useState(GetCurrentMonth());
	const CurrentYear = new Date().getFullYear();
	const [Year, setYear] = useState(CurrentYear);
	const Years = Array.from({ length: 100 }, (v, i) => CurrentYear - i);
	const AllClients = useSelector((state) => state.cred.creds);

	const Clients = useMemo(
		() =>
			AllClients.filter(
				(client) =>
					client.credLinker != "0" &&
					parseInt(client.deleted) === 0 &&
					parseInt(client.client) === 1 &&
					(parseInt(client.groupLinker) === parseInt(Group) ||
						Group === "all") &&
					((client.clientReg || "").toLowerCase().search(Search.toLowerCase()) >
						-1 ||
						(client.firstname || "")
							.toLowerCase()
							.search(Search.toLowerCase()) > -1 ||
						(client.lastname || "").toLowerCase().search(Search.toLowerCase()) >
							-1 ||
						(client.surname || "").toLowerCase().search(Search.toLowerCase()) >
							-1 ||
						(client.email || "").toLowerCase().search(Search.toLowerCase()) >
							-1 ||
						(client.contact || "").toLowerCase().search(Search.toLowerCase()) >
							-1 ||
						Search === "") &&
					parseInt(client.linker) !== parseInt(inst.linker)
			).sort((a, b) => parseInt(b.linker) - parseInt(a.linker)),
		[AllClients, Group, Search]
	);

	const LoanIntAutoCalc = useMemo(
		() => () => {
			Clients.map((client) => {
				Accounts.filter(
					(account) =>
						account.status === "loan" && account.type === "reducing-balance"
				).map((account) => {
					let ClientAccountEntries = Entrys.filter(
						(entry) =>
							parseInt(entry.clientLinker) === parseInt(client.linker) &&
							parseInt(entry.accountLinker) === parseInt(account.linker)
					);
					let LoanBal =
						ClientAccountEntries.filter(
							(entry) =>
								entry.type === "issued-loan" || entry.type == "loan-interest"
						).reduce((a, b) => +a + +b.amount, 0) -
						ClientAccountEntries.filter(
							(entry) => entry.type === "payment"
						).reduce((a, b) => +a + +b.amount, 0);
					let IntEntries = ClientAccountEntries.filter(
						(entry) => entry.type == "loan-interest"
					).sort((a, b) => parseInt(b.linker) - parseInt(a.linker));
					if (
						LoanBal > 0 &&
						(IntEntries.length < 1 ||
							IntEntries[0]?.linker < Date.now() - 31 * 24 * 60 * 60 * 1000)
					) {
						addLiveEntry(
							{
								amount: (LoanBal * account.rate) / 100,
								details: `${
									new Date().getMonth() + 1
								}/${new Date().getFullYear()} Interest`,
								security: "",
								guarantors: "",
								principal: "",
								period: "",
								type: "loan-interest",
								accountLinker: account.linker,
								modeLinker: "",
								clientLinker: client.linker,
								code: "",
								instLinker: ActiveCred.linker,
								credLinker: ActiveCred.linker,
								live: 1,
								status: 0,
								trace: Date.now(),
								deleted: 0,
								linker: Date.now(),
							},
							ActiveCred.instLinker,
							User.token,
							dispatch
						);
					}
				});
			});
		},
		[]
	);

	const BFEntrys = useMemo(
		() =>
			Entrys.filter(
				(entry) =>
					(AccountView === "all" ||
						parseInt(entry.accountLinker) === parseInt(AccountView)) &&
					(Summary === "current" || parseInt(entry.linker) <= StartLinker)
			),
		[Entrys, AccountView, Summary, Week, Month, Year, StartLinker]
	);

	const CFEntrys = useMemo(
		() =>
			Entrys.filter(
				(entry) =>
					(AccountView === "all" ||
						parseInt(entry.accountLinker) === parseInt(AccountView)) &&
					(Summary === "current" || parseInt(entry.linker) <= EndLinker)
			),
		[Entrys, AccountView, Summary, Week, Month, Year, EndLinker]
	);

	const CurrentEntrys = useMemo(
		() =>
			Entrys.filter(
				(entry) =>
					(AccountView === "all" ||
						parseInt(entry.accountLinker) === parseInt(AccountView)) &&
					(Summary === "current" ||
						(parseInt(entry.linker) >= StartLinker &&
							parseInt(entry.linker) <= EndLinker))
			),
		[Entrys, AccountView, Summary, Week, Month, Year, StartLinker, EndLinker]
	);

	const BFEntrysSum = useMemo(
		() =>
			BFEntrys.filter((entry) =>
				Clients.some(
					(client) => parseInt(client.linker) === parseInt(entry.clientLinker)
				)
			).reduce((acc, current) => {
				const { accountLinker, type, amount } = current;
				const key = `${accountLinker}-${type}`;

				if (!acc[key]) {
					acc[key] = { accountLinker, type, totalAmount: 0 };
				}

				acc[key].totalAmount += +amount;

				return acc;
			}, {}),
		[AccountView, Summary, Week, Month, Year, Clients, BFEntrys]
	);

	const CFEntrysSum = useMemo(
		() =>
			CFEntrys.filter((entry) =>
				Clients.some(
					(client) => parseInt(client.linker) === parseInt(entry.clientLinker)
				)
			).reduce((acc, current) => {
				const { accountLinker, type, amount } = current;
				const key = `${accountLinker}-${type}`;

				if (!acc[key]) {
					acc[key] = { accountLinker, type, totalAmount: 0 };
				}

				acc[key].totalAmount += +amount;

				return acc;
			}, {}),
		[AccountView, Summary, Week, Month, Year, Clients, CFEntrys]
	);

	const CurrentEntrysSum = useMemo(
		() =>
			CurrentEntrys.filter((entry) =>
				Clients.some(
					(client) => parseInt(client.linker) === parseInt(entry.clientLinker)
				)
			).reduce((acc, current) => {
				const { accountLinker, type, amount } = current;
				const key = `${accountLinker}-${type}`;

				if (!acc[key]) {
					acc[key] = { accountLinker, type, totalAmount: 0 };
				}

				acc[key].totalAmount += +amount;

				return acc;
			}, {}),
		[AccountView, Summary, Week, Month, Year, Clients, CurrentEntrys]
	);

	const EntrysSum = useMemo(
		() =>
			Entrys.filter((entry) =>
				Clients.some(
					(client) => parseInt(client.linker) === parseInt(entry.clientLinker)
				)
			).reduce((acc, current) => {
				const { accountLinker, type, amount } = current;
				const key = `${accountLinker}-${type}`;

				if (!acc[key]) {
					acc[key] = { accountLinker, type, totalAmount: 0 };
				}

				acc[key].totalAmount += +amount;

				return acc;
			}, {}),
		[AccountView, Summary, Week, Month, Year, Entrys, Clients]
	);

	const AllCFEntrysSum = useMemo(
		() =>
			Entrys.filter(
				(entry) =>
					(Summary === "current" || parseInt(entry.linker) <= EndLinker) &&
					Clients.some(
						(client) => parseInt(client.linker) === parseInt(entry.clientLinker)
					)
			).reduce((acc, current) => {
				const { accountLinker, type, amount } = current;
				const key = `${type}`;

				if (!acc[key]) {
					acc[key] = { type, totalAmount: 0 };
				}

				acc[key].totalAmount += +amount;

				return acc;
			}, {}),
		[AccountView, Summary, Week, Month, Year, Clients, Entrys]
	);

	const GroupExpensesSum = useMemo(
		() =>
			Expenses.filter(
				(expense) =>
					parseInt(expense.deleted) === 0 &&
					(Summary === "current" || parseInt(expense.linker) <= EndLinker) &&
					(expense.catLinker === "service-fee" ||
						expense.catLinker === "other") &&
					(parseInt(expense.branchLinker) === parseInt(Group) ||
						Group === "all")
			).reduce((a, b) => +a + +b.amount, 0),
		[AccountView, Summary, Week, Month, Year, Expenses]
	);

	return (
		<div>
			<div className=" d-flex justify-content-around mt-1">
				<p className="text-center h6 text-primary text-capitalize">
					{Clients.length} Clients
				</p>{" "}
				<button
					className="btn btn-sm btn-primary  mx-1"
					onClick={() => {
						setShowModal(true);
						setType("add");
						setClient({
							firstname: "",
							lastname: "",
							surname: "",
							clientReg: "",
							contact: "",
							email: "",
							status: 0,
							income: "",
							location: "",
							groupLinker: "",
							branchLinker: ActiveCred.branchLinker,
							nokName: "",
							nokContact: "",
							client: 1,
						});
					}}
				>
					<PersonAdd /> Register Client
				</button>{" "}
				{inst.sub === "Kenya" ? (
					<button
						className="btn btn-sm btn-primary"
						onClick={() => {
							Navigate(`/${inst.name}/bulk-sms`);
						}}
					>
						<Envelope /> Sms Clients
					</button>
				) : null}
				{Groups.length > 0 ? (
					<button
						className="btn btn-sm btn-primary  mx-1"
						onClick={() => {
							Navigate(`/${inst.name}/group-expenses`);
						}}
					>
						<People /> Group Expenses
					</button>
				) : null}
				<button
					className="btn btn-sm btn-primary  mx-1"
					onClick={() => {
						Navigate(`/${inst.name}/dividend`);
					}}
				>
					<Calculator /> Dividend
				</button>
			</div>
			<div className=" d-flex justify-content-around mt-1">
				<div>
					<select
						onChange={(e) => {
							setSummary(e.target.value);
							return e.target.value === "weekly"
								? GetWeekTimestamps(Week)
								: e.target.value === "monthly"
								? GetMonthTimestamps(Month)
								: e.target.value === "annual"
								? GetYearTimestamps(Year)
								: null;
						}}
						value={Summary}
						className="rounded form-control"
					>
						<option value={"current"}>Current Sum</option>
						<option value={"weekly"}>Weekly Sum</option>
						<option value={"monthly"}>Monthly Sum</option>
						<option value={"annual"}>Annual Sum</option>
					</select>
				</div>
				{Groups.length > 0 ? (
					<div>
						<select
							className="form-control rounded"
							value={Group}
							onChange={(e) => {
								setGroup(e.target.value);
								setSearch("");
							}}
						>
							<option value={"all"}>All Groups</option>
							{Groups.map((group) => (
								<option key={group.linker} value={group.linker}>
									{group.name}
								</option>
							))}
						</select>
					</div>
				) : null}

				{Summary === "weekly" ? (
					<div>
						<input
							type="week"
							value={Week}
							onChange={(e) => {
								setWeek(e.target.value);
								GetWeekTimestamps(e.target.value);
							}}
							onClick={(e) => e.target.showPicker()}
							className="rounded form-control"
						/>
					</div>
				) : Summary === "monthly" ? (
					<div>
						<input
							type="month"
							value={Month}
							onClick={(e) => e.target.showPicker()}
							onChange={(e) => {
								setMonth(e.target.value);
								GetMonthTimestamps(e.target.value);
							}}
							className="rounded form-control"
						/>
					</div>
				) : Summary === "annual" ? (
					<div>
						<select
							className="rounded  form-control p-1"
							value={Year}
							onChange={(e) => {
								setYear(e.target.value);
								GetYearTimestamps(e.target.value);
							}}
						>
							{Years.map((year) => (
								<option key={year} value={year}>
									{year}
								</option>
							))}
						</select>
					</div>
				) : null}

				{AllAccounts.length > 0 ? (
					<div>
						<select
							className="rounded form-control"
							value={AccountView}
							onChange={(e) => setAccountView(e.target.value)}
						>
							<option value={"all"}>All Accounts</option>
							{AllAccounts.map((account) => (
								<option value={account.linker}>{account.name}</option>
							))}
						</select>
					</div>
				) : null}
			</div>
			<table
				className="table table-sm table-bordered table-responsive"
				id="clients"
			>
				<thead className="thead-dark">
					<tr>
						<th colSpan={2}>
							{Groups.length > 0 ? (
								<table className="table table-sm table-bordered">
									<tr>
										<th>
											<small>Banking :</small>
										</th>
										<td>
											<small className="text-end">
												{(AllCFEntrysSum["saving"]?.totalAmount || 0) +
													(AllCFEntrysSum["payment"]?.totalAmount || 0) -
													(AllCFEntrysSum["withdrawal"]?.totalAmount || 0) -
													(AllCFEntrysSum["issued-loan"]?.totalAmount || 0) -
													(AllCFEntrysSum["dividend"]?.totalAmount || 0) -
													GroupExpensesSum}
											</small>
										</td>
									</tr>
									<tr>
										<th>
											<small>Int :</small>
										</th>
										<td>
											<small className="text-end">
												{(AllCFEntrysSum["loan-interest"]?.totalAmount || 0) +
													(AllCFEntrysSum["fine"]?.totalAmount || 0) -
													(AllCFEntrysSum["dividend"]?.totalAmount || 0) -
													GroupExpensesSum}
											</small>
										</td>
									</tr>
									<tr>
										<th>
											<small>Total Funds :</small>
										</th>
										<td>
											<small className="text-end">
												{(AllCFEntrysSum["issued-loan"]?.totalAmount || 0) -
													(AllCFEntrysSum["payment"]?.totalAmount || 0) +
													(AllCFEntrysSum["loan-interest"]?.totalAmount || 0) +
													((AllCFEntrysSum["saving"]?.totalAmount || 0) +
														(AllCFEntrysSum["payment"]?.totalAmount || 0) -
														(AllCFEntrysSum["withdrawal"]?.totalAmount || 0) -
														(AllCFEntrysSum["issued-loan"]?.totalAmount || 0) -
														GroupExpensesSum)}
											</small>
										</td>
									</tr>
								</table>
							) : null}

							<input
								type={"text"}
								placeholder={`Search Client`}
								value={Search}
								onChange={(e) => setSearch(e.target.value)}
								className="rounded form-control"
							/>
						</th>
						{Groups.length > 0 ? <th>Group</th> : null}
						<>
							{SavingAccounts.map((account) => (
								<th>{account.name}</th>
							))}
							{LoanAccounts.map((account) => (
								<th>{account.name}</th>
							))}
						</>
						{Summary !== "current" ? (
							<>
								{SavingAccounts.map((account) => (
									<>
										<th>
											{account.name} <br /> Saved
										</th>
										<th>
											{account.name} <br /> W/D
										</th>
									</>
								))}
								{LoanAccounts.map((account) => (
									<>
										<th>
											{account.name} <br /> Repaid & Int
										</th>
										<th>
											{account.name} <br /> Issued
										</th>
										<th>
											{account.name} <br /> Int
										</th>
									</>
								))}{" "}
								{SavingAccounts.map((account) => (
									<th>{account.name} CF</th>
								))}
								{LoanAccounts.map((account) => (
									<th>{account.name} CF</th>
								))}
							</>
						) : null}
					</tr>
					<tr>
						<th>ACCNo</th>
						<th>Details</th>
						{Groups.length > 0 ? (
							<th>
								<select
									className="form-control rounded"
									value={Group}
									onChange={(e) => {
										setGroup(e.target.value);
										setSearch("");
									}}
								>
									<option value={"all"}>All Groups</option>
									{Groups.map((group) => (
										<option key={group.linker} value={group.linker}>
											{group.name}
										</option>
									))}
								</select>
							</th>
						) : null}
						{SavingAccounts.map((account) => (
							<th>
								{Summary === "current"
									? (EntrysSum[`${account.linker}-saving`]?.totalAmount || 0) +
									  (EntrysSum[`${account.linker}-savings-interest`]
											?.totalAmount || 0) -
									  (EntrysSum[`${account.linker}-withdrawal`]?.totalAmount ||
											0) -
									  (EntrysSum[`${account.linker}-fine`]?.totalAmount || 0)
									: (BFEntrysSum[`${account.linker}-saving`]?.totalAmount ||
											0) +
									  (BFEntrysSum[`${account.linker}-savings-interest`]
											?.totalAmount || 0) -
									  (BFEntrysSum[`${account.linker}-withdrawal`]?.totalAmount ||
											0) -
									  (BFEntrysSum[`${account.linker}-fine`]?.totalAmount || 0)}
							</th>
						))}
						{LoanAccounts.map((account) => (
							<th>
								{Summary === "current"
									? (EntrysSum[`${account.linker}-issued-loan`]?.totalAmount ||
											0) +
									  (EntrysSum[`${account.linker}-loan-interest`]
											?.totalAmount || 0) -
									  (EntrysSum[`${account.linker}-payment`]?.totalAmount || 0)
									: (BFEntrysSum[`${account.linker}-issued-loan`]
											?.totalAmount || 0) +
									  (BFEntrysSum[`${account.linker}-loan-interest`]
											?.totalAmount || 0) -
									  (BFEntrysSum[`${account.linker}-payment`]?.totalAmount ||
											0)}
							</th>
						))}
						{Summary !== "current" ? (
							<>
								{SavingAccounts.map((account) => (
									<>
										<th>
											{(CurrentEntrysSum[`${account.linker}-saving`]
												?.totalAmount || 0) +
												(CurrentEntrysSum[`${account.linker}-savings-interest`]
													?.totalAmount || 0)}
										</th>
										<th>
											{(CurrentEntrysSum[`${account.linker}-withdrawal`]
												?.totalAmount || 0) +
												(CurrentEntrysSum[`${account.linker}-fine`]
													?.totalAmount || 0)}
										</th>
									</>
								))}
								{LoanAccounts.map((account) => (
									<>
										<th>
											{CurrentEntrysSum[`${account.linker}-payment`]
												?.totalAmount || 0}
										</th>
										<th>
											{CurrentEntrysSum[`${account.linker}-issued-loan`]
												?.totalAmount || 0}
										</th>
										<th>
											{CurrentEntrysSum[`${account.linker}-loan-interest`]
												?.totalAmount || 0}
										</th>
									</>
								))}
								{SavingAccounts.map((account) => (
									<th>
										{(CFEntrysSum[`${account.linker}-saving`]?.totalAmount ||
											0) +
											(CFEntrysSum[`${account.linker}-savings-interest`]
												?.totalAmount || 0) -
											(CFEntrysSum[`${account.linker}-withdrawal`]
												?.totalAmount || 0) -
											(CFEntrysSum[`${account.linker}-fine`]?.totalAmount || 0)}
									</th>
								))}
								{LoanAccounts.map((account) => (
									<th>
										{(CFEntrysSum[`${account.linker}-issued-loan`]
											?.totalAmount || 0) +
											(CFEntrysSum[`${account.linker}-loan-interest`]
												?.totalAmount || 0) -
											(CFEntrysSum[`${account.linker}-payment`]?.totalAmount ||
												0)}
									</th>
								))}
							</>
						) : null}
					</tr>
				</thead>
				<tbody>
					<PlainList
						list={Clients}
						renderOnScroll
						renderItem={(client) => (
							<ClientDisp
								client={client}
								setClient={setClient}
								setType={setType}
								setShowModal={setShowModal}
								setSmsModal={setSmsModal}
								setEntry={setEntry}
								setEntryModal={setEntryModal}
								setAccount={setAccount}
								key={client.linker}
								Accounts={Accounts}
								Summary={Summary}
								BFEntrys={BFEntrys}
								CFEntrys={CFEntrys}
								CurrentEntrys={CurrentEntrys}
								LoanAccounts={LoanAccounts}
								SavingAccounts={SavingAccounts}
							></ClientDisp>
						)}
					/>
				</tbody>
			</table>
			<ClientCrud
				setShowModal={setShowModal}
				ShowModal={ShowModal}
				client={Client}
				setClient={setClient}
				type={Type}
			></ClientCrud>
			<SingleSms
				Client={Client}
				SmsModal={SmsModal}
				setSmsModal={setSmsModal}
			></SingleSms>
			<ClientEntry
				entry={Entry}
				setEntry={setEntry}
				ShowModal={EntryModal}
				setShowModal={setEntryModal}
				account={Account}
				type={Type}
				client={Client}
			></ClientEntry>
			<div className="d-flex justify-content-center">
				<GeneratePdf id={"clients"} name={`clients`}></GeneratePdf>
			</div>
		</div>
	);
};

export default Clients;
