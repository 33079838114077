import React, { useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { updateLiveEntry } from "../../../Network/EntryApi";

const DividendCrud = ({
	Client,
	Dividend,
	setDividend,
	Type,
	ShowModal,
	setShowModal,
}) => {
	const [Loading, setLoading] = useState(false);
	//redux dispatch
	const dispatch = useDispatch();

	const User = useSelector((state) => state.auth.user);
	const ActiveCred = useSelector((state) => state.cred.activeCred);

	const HandleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		if (Type === "edit" || Type === "delete") {
			let trace = Date.now();
			let credLinker = ActiveCred.linker;
			let deleted = Type === "delete" ? 1 : 0;

			await updateLiveEntry(
				{ ...Dividend, trace, credLinker, deleted },
				User.token,
				dispatch
			);
		}
		setLoading(false);
		setShowModal(false);
	};

	return (
		<Modal
			show={ShowModal}
			onHide={() => setShowModal(false)}
			backdrop="static"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title className="text-capitalize text-center">
					{Type} {Client.firstname} {Client.lastname} Dividend <br /> For{" "}
					{Dividend.accountLinker}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form onSubmit={HandleSubmit}>
					<div className="form-group">
						<label>Dividend Amount : </label>
						<input
							value={Dividend.amount}
							required
							placeholder="Enter Amount"
							className="form-control rounded"
							onChange={(e) =>
								setDividend({ ...Dividend, amount: e.target.value })
							}
						/>
						<hr />
						<div className="d-flex justify-content-around">
							{Loading ? (
								<span className="spinner-border text-primary"></span>
							) : Type === "delete" ? (
								<Button variant="danger" type="submit">
									Delete
								</Button>
							) : (
								<Button variant="primary" type="submit">
									Save
								</Button>
							)}
							<Button
								variant="secondary"
								type="button"
								onClick={() => setShowModal(false)}
							>
								Cancel
							</Button>
						</div>
					</div>
				</Form>
			</Modal.Body>
		</Modal>
	);
};

export default DividendCrud;
