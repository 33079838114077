import React, { useState } from "react";
import { useSelector } from "react-redux";
const Calc = () => {
  const Accounts = useSelector((state) => state.account.accounts).filter(
    (account) =>
      parseInt(account.deleted) === 0 && account.type === "reducing-balance"
  );

  const [Int, setInt] = useState(0);
  const [Principal, setPrincipal] = useState(0);
  const [Period, setPeriod] = useState(0);
  const [Amount, setAmount] = useState(0);
  //const [Payment, SetPayment] = useState(0);
  //const [Total, setTotal] = useState(Period * Amount);

  const PaymentCalc = (amount, period, int) => {
    console.log((amount * int) / 100 / (1 - Math.pow(1 + int / 100, -period)));
    setPrincipal((amount * int) / 100 / (1 - Math.pow(1 + int / 100, -period)));
  };

  return (
    <div>
      <p className="h6 text-center">Reducing Balance Loan Calculator</p>
      <div className="row d-flex justify-content-center">
        <div className="col-12 col-md-8">
          {Accounts.length > 0 ? (
            <div className="form-group">
              <label>Select An Account :</label>
              <select
                onChange={(e) => {
                  let account = Accounts.find(
                    (account) =>
                      parseInt(account.linker) === parseInt(e.target.value)
                  );
                  setInt(account?.rate || 0);
                  PaymentCalc(Amount, Period, account?.rate || 0);
                }}
                className="form-control rounded"
              >
                <option value={{}}>Select An Account</option>
                {Accounts.map((account) => (
                  <option key={account.linker} value={account.linker}>
                    {account.name}
                  </option>
                ))}
              </select>
              <hr />
            </div>
          ) : null}
          <div className="form-group">
            <label>Interest Rate Per Month :</label>
            <input
              value={Int}
              onChange={(e) => {
                setInt(e.target.value);
                PaymentCalc(Amount, Period, e.target.value);
              }}
              placeholder="Enter Interest"
              className="form-control rounded"
              type={"number"}
            />
            <hr />
          </div>
          <div className="form-group">
            <label>Loan Amount :</label>
            <input
              value={Amount}
              onChange={(e) => {
                setAmount(e.target.value);

                PaymentCalc(e.target.value, Period, Int);
              }}
              placeholder="Enter Loan Amount"
              className="form-control rounded"
              type={"number"}
            />
            <hr />
          </div>
          <div className="form-group">
            <label>
              Repayment Period: <em>{`{In Months}`}</em>
            </label>
            <input
              value={Period}
              onChange={(e) => {
                setPeriod(e.target.value);
                PaymentCalc(Amount, e.target.value, Int);
              }}
              placeholder="Enter Repayment Period"
              className="form-control rounded"
              type={"number"}
            />
            <hr />
          </div>
          <div className="form-group">
            <label>
              Monthly Payment: <em>{`{Principal + Interest}`}</em>
            </label>
            <input
              value={Principal}
              placeholder="Enter Amount Repayable"
              className="form-control rounded"
              type={"number"}
              readOnly
            />
            <hr />
          </div>
          <div className="form-group">
            <label>
              Total Amount Repayable: <em>{`{Amount * Period}`}</em>
            </label>
            <input
              value={Period * Principal}
              onChange={(e) => {}}
              readOnly
              className="form-control rounded"
              type={"number"}
            />
            <hr />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Calc;
