import React from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ClientApply } from "../../Network/ClientApi";

const ApplicationCrud = (props) => {
  //redux dispatch
  const dispatch = useDispatch();

  const User = useSelector((state) => state.auth.user);
  const online = useSelector((state) => state.online.online);
  const ActiveCred = useSelector((state) => state.cred.activeCred);
  const Accounts = useSelector((state) => state.account.accounts).filter(
    (account) => parseInt(account.deleted) === 0
  );

  const SaveApplication = async () => {
    ClientApply(
      User.token,
      ActiveCred.instLinker,
      props.application.accountLinker,
      props.application.appliedAmount,
      props.application.details,
      dispatch,
      props.application.id,
      props.type,
      props.setLoading,
      props.setShowModal
    );
  };

  const HandleSubmit = (e) => {
    e.preventDefault();
    SaveApplication();
    props.setShowModal(false);
  };

  return (
    <Modal
      show={props.ShowModal}
      onHide={() => props.setShowModal(false)}
      backdrop="static"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <p className="text-capitalize text-center">
            {props.type} Application
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={HandleSubmit}>
          <div className="form-group">
            {Accounts.length > 0 ? (
              <div className="form-group">
                <label className="mb-2 text-capitalize">Account:</label>
                <select
                  className="rounded form-control"
                  value={props.application.accountLinker}
                  onChange={(e) =>
                    props.setApplication({
                      ...props.application,
                      accountLinker: e.target.value,
                    })
                  }
                  required
                  readOnly={props.type === "delete" ? true : false}
                >
                  <option value={""}>Select Account</option>
                  {Accounts.map((account, index) => (
                    <option key={index} value={account.linker}>
                      {account.name}
                    </option>
                  ))}
                </select>
                <hr />
              </div>
            ) : null}
          </div>

          <div className="form-group">
            <label className="mb-2 text-capitalize">Amount Applied:</label>
            <input
              className="rounded form-control"
              placeholder={`enter amount applied`}
              value={props.application.appliedAmount}
              onChange={(e) =>
                props.setApplication({
                  ...props.application,
                  appliedAmount: e.target.value,
                })
              }
              type="number"
              required
              readOnly={props.type === "delete" ? true : false}
            />
            <hr />
          </div>

          <div className="form-group">
            <label className="mb-2 text-capitalize">Application Details:</label>
            <textarea
              className="rounded form-control"
              placeholder={`enter application details`}
              value={props.application.details}
              onChange={(e) =>
                props.setApplication({
                  ...props.application,
                  details: e.target.value,
                })
              }
              required
              readOnly={props.type === "delete" ? true : false}
            />
            <hr />
          </div>

          <div className="d-flex justify-content-around mb-2">
            {props.Loading ? (
              <span className="spinner-border text-primary"></span>
            ) : (
              <Button variant="primary" type="submit">
                Save
              </Button>
            )}
            <Button
              variant="secondary"
              type="button"
              onClick={() => props.setShowModal(false)}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ApplicationCrud;
