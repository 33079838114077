import { createSlice } from "@reduxjs/toolkit";

export const ApplicationSlice = createSlice({
  name: "application",
  initialState: {
    applications: [],
  },
  reducers: {
    fetchApplications: (state, action) => {
      state.applications = action.payload;
    },
    addApplication: (state, action) => {
      state.applications = [action.payload, ...state.applications];
    },
    updateApplication: (state, action) => {
      let index = state.applications.findIndex(
        (application) =>
          parseInt(application.linker) === parseInt(action.payload.linker)
      );

      let prevApplications = [...state.applications];

      prevApplications.splice(
        index < 0 ? 1 : index,
        index < 0 ? 0 : 1,
        action.payload
      );

      state.applications = prevApplications;
    },
    deleteApplication: (state, action) => {
      let newApplications = state.applications.filter(
        (application) =>
          parseInt(application.linker) !== parseInt(action.payload.linker)
      );
      state.applications = newApplications;
    },
  },
});

export const {
  fetchApplications,
  addApplication,
  updateApplication,
  deleteApplication,
} = ApplicationSlice.actions;

export default ApplicationSlice.reducer;
