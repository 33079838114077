import React, { useState, useEffect } from "react";
import ClientEntry from "../Members/ClientEntry";
import { useDispatch, useSelector } from "react-redux";
import NoEntry from "./NoEntries";
import { fetchCreds } from "../../reducers/CredSlice";
import EntryDisp from "./EntryDisp";
import { FetchEntrys } from "../../Network/EntryApi";
import { PlainList } from "flatlist-react";
import GeneratePdf from "../../Utils/GeneratePdf";

const Entries = () => {
	useEffect(() => {
		FetchEntrys(User.token, ActiveCred.instLinker, dispatch, online);
	}, []);

	const [EntryModal, setEntryModal] = useState(false);
	const [Entry, setEntry] = useState({ name: "" });
	const [Client, setClient] = useState({ name: "" });
	const [ActiveAc, setActiveAc] = useState({ name: "" });
	const [Type, setType] = useState("add");
	const [By, setBy] = useState("all");
	const [Day, setDay] = useState("all");
	const [Month, setMonth] = useState("all");
	const [Year, setYear] = useState("all");
	const [Mode, setMode] = useState("all");
	const [Account, setAccount] = useState("all");
	const [EntryType, setEntryType] = useState("all");
	const [Branch, setBranch] = useState("all");

	//redux dispatch
	const dispatch = useDispatch();

	const User = useSelector((state) => state.auth.user);
	const online = useSelector((state) => state.online.online);
	const ActiveCred = useSelector((state) => state.cred.activeCred);
	const Entries = useSelector((state) => state.entry.entrys)
		.filter(
			(entry) => parseInt(entry.deleted) === 0 && entry.type !== "dividend"
		)
		.sort((a, b) => parseInt(a.linker) - parseInt(b.linker));
	const Groups = useSelector((state) => state.group.groups).filter(
		(group) => parseInt(group.deleted) === 0
	);
	const [Group, setGroup] = useState("all");
	const Creds = useSelector((state) => state.cred.creds);
	const Modes = useSelector((state) => state.mode.modes).filter(
		(mode) => parseInt(mode.deleted) === 0
	);
	const Branches = useSelector((state) => state.branch.branchs).filter(
		(branch) => parseInt(branch.deleted) === 0
	);
	const Accounts = useSelector((state) => state.account.accounts).filter(
		(account) => parseInt(account.deleted) === 0
	);

	const FindUser = (linker) => {
		let cred = Creds.find((cred) => parseInt(cred.linker) == parseInt(linker));

		return cred || { email: "unknown" };
	};

	const Clients = useSelector((state) => state.cred.creds).filter(
		(client) =>
			client.credLinker != "0" &&
			parseInt(client.deleted) === 0 &&
			parseInt(client.client) === 1 &&
			(parseInt(client.groupLinker) === parseInt(Group) || Group === "all")
	);

	const SearchEntry = Entries.filter(
		(entry) =>
			parseInt(entry.deleted) === 0 &&
			(parseInt(entry.modeLinker) === parseInt(Mode) || Mode === "all") &&
			(parseInt(entry.accountLinker) === parseInt(Account) ||
				Account === "all") &&
			(parseInt(entry.branchLinker) === parseInt(Branch) || Branch === "all") &&
			(entry.type === EntryType || EntryType === "all") &&
			(parseInt(
				new Date(parseInt(entry.linker)).toLocaleDateString().split("/")[2]
			) === parseInt(Year) ||
				Year === "all") &&
			(parseInt(
				new Date(parseInt(entry.linker)).toLocaleDateString().split("/")[0]
			) === parseInt(Month) ||
				Year === "all" ||
				Month === "all") &&
			(parseInt(
				new Date(parseInt(entry.linker)).toLocaleDateString().split("/")[1]
			) === parseInt(Day) ||
				Year === "all" ||
				Month === "all" ||
				Day == "all") &&
			(parseInt(entry.credLinker) === parseInt(By) || By === "all") &&
			Clients.some(
				(client) => parseInt(client.linker) === parseInt(entry.clientLinker)
			)
	).sort((a, b) => parseInt(b.linker) - parseInt(a.linker));

	const FindAccount = Accounts.find(
		(account) => parseInt(account.linker) === parseInt(Account)
	);

	return (
		<div>
			<div id="entries">
				<div className="d-flex justify-content-around mt-2">
					<p className="text-center h6 text-primary text-capitalize">
						{SearchEntry.length} Entries
					</p>
				</div>

				<table className="table table-sm table-bordered">
					<thead className="thead-dark">
						<tr>
							<th scope="colum">Mode</th>
							<th>Ac</th>
							<th scope="column">Type</th>
							{Branches.length > 0 ? <th scope="column">Branch</th> : null}
							{Groups.length > 0 ? <th scope="column">Group</th> : null}
							<th>Staff</th>

							<th scope="column">Date</th>
							<th scope="column">Details</th>
							<th scope="column">Amount</th>
						</tr>
						<tr>
							<th scope="column">
								<select
									className="form-control rounded"
									value={Mode}
									onChange={(e) => setMode(e.target.value)}
								>
									<option value={"all"}>All Modes</option>
									{Modes.map((mode, index) => (
										<option key={index} value={mode.linker}>
											{mode.name}
										</option>
									))}
								</select>
							</th>
							<th scope="column">
								<select
									className="form-control rounded"
									value={Account}
									onChange={(e) => setAccount(e.target.value)}
								>
									<option value={"all"}>All Acs</option>
									{Accounts.map((account, index) => (
										<option key={index} value={account.linker}>
											{account.name}
										</option>
									))}
								</select>
							</th>
							<th scope="column">
								<select
									className="form-control rounded"
									value={EntryType}
									onChange={(e) => setEntryType(e.target.value)}
								>
									<option value={"all"}>All Types</option>
									{Account === "all" || FindAccount?.status === "saving" ? (
										<>
											{" "}
											<option value="saving">Savings</option>
											<option value="savings-interest">
												Savings Interest
											</option>{" "}
											<option value="withdrawal">Withdrawal</option>
											<option value="fine">Fine</option>
										</>
									) : null}
									{Account === "all" || FindAccount?.status === "loan" ? (
										<>
											{" "}
											<option value="issued-loan">Loan Issued</option>
											<option value="loan-interest">Loan Interest</option>
											<option value="payment">Loan Payment</option>
										</>
									) : null}
								</select>
							</th>
							{Branches.length > 0 ? (
								<th scope="column">
									<select
										className="form-control rounded"
										value={Branch}
										onChange={(e) => setBranch(e.target.value)}
									>
										<option value={"all"}>All Branches</option>
										{Branches.map((branch, index) => (
											<option key={index} value={branch.linker}>
												{branch.name}
											</option>
										))}
									</select>
								</th>
							) : null}
							{Groups.length > 0 ? (
								<th scope="column">
									<select
										className="form-control rounded"
										value={Group}
										onChange={(e) => setGroup(e.target.value)}
									>
										<option value={"all"}>All Groups</option>
										{Groups.map((group, index) => (
											<option key={index} value={group.linker}>
												{group.name}
											</option>
										))}
									</select>
								</th>
							) : null}
							<th>
								<select
									className="form-control rounded"
									value={By}
									onChange={(e) => setBy(e.target.value)}
								>
									<option value={"all"}>All Staffs</option>
									<PlainList
										list={[
											...new Set(Entries.map((entry) => entry.credLinker)),
										]}
										renderOnScroll
										renderItem={(staff) => (
											<option value={staff} key={staff}>
												{FindUser(staff).email}
											</option>
										)}
									/>
								</select>
							</th>

							<th colSpan={2}>
								<div className="d-flex">
									<select
										className="form-control rounded"
										value={Year}
										onChange={(e) => setYear(e.target.value)}
									>
										<option value={"all"}>All Yrs</option>
										{[
											...new Set(
												Entries.map(
													(entry) =>
														new Date(parseInt(entry.linker))
															.toLocaleDateString()
															.split("/")[2]
												)
											),
										].map((yr) => (
											<option key={yr} value={yr}>
												{yr}
											</option>
										))}
									</select>
									{Year !== "all" ? (
										<select
											className="form-control rounded"
											value={Month}
											onChange={(e) => setMonth(e.target.value)}
										>
											<option value={"all"}>All Mths</option>
											{[
												...new Set(
													Entries.map(
														(entry) =>
															new Date(parseInt(entry.linker))
																.toLocaleDateString()
																.split("/")[0]
													)
												),
											].map((mth) => (
												<option key={mth} value={mth}>
													{mth}
												</option>
											))}
										</select>
									) : null}
									{Month !== "all" && Year !== "all" ? (
										<select
											className="form-control rounded"
											value={Day}
											onChange={(e) => setDay(e.target.value)}
										>
											<option value={"all"}>All Dates</option>
											{[
												...new Set(
													Entries.map(
														(entry) =>
															new Date(parseInt(entry.linker))
																.toLocaleDateString()
																.split("/")[1]
													)
												),
											].map((day) => (
												<option key={day} value={day}>
													{day}
												</option>
											))}
										</select>
									) : null}
								</div>
							</th>

							<th>{SearchEntry.reduce((a, b) => +a + +b.amount, 0)}</th>
						</tr>
					</thead>
					{SearchEntry.length > 0 ? (
						<tbody>
							<PlainList
								list={SearchEntry}
								renderOnScroll
								renderItem={(entry) => (
									<EntryDisp
										setShowModal={setEntryModal}
										entry={entry}
										setEntry={setEntry}
										setClient={setClient}
										setAccount={setActiveAc}
										setType={setType}
										key={entry.linker}
									></EntryDisp>
								)}
							/>
						</tbody>
					) : (
						<NoEntry></NoEntry>
					)}
				</table>
			</div>
			<GeneratePdf id={"entries"} name={"entries"}></GeneratePdf>

			<ClientEntry
				entry={Entry}
				setEntry={setEntry}
				ShowModal={EntryModal}
				setShowModal={setEntryModal}
				account={ActiveAc}
				type={Type}
				client={Client}
			></ClientEntry>
		</div>
	);
};

export default Entries;
