import React, { useState, useEffect } from "react";
import BranchCrud from "./BranchCrud";
import { useDispatch, useSelector } from "react-redux";
import { PlusCircle, Trash, PencilSquare } from "react-bootstrap-icons";
import NoBranch from "./NoBranch";
import { FetchBranchs } from "../../../Network/BranchApi";
import GeneratePdf from "../../../Utils/GeneratePdf";

const Branchs = () => {
	useEffect(() => {
		FetchBranchs(User.token, ActiveCred.instLinker, dispatch, online);
	}, []);

	const [ShowModal, setShowModal] = useState(false);
	const [Branch, setBranch] = useState({ name: "" });
	const [Search, setSearch] = useState("");
	const [Type, setType] = useState("add");

	//redux dispatch
	const dispatch = useDispatch();

	const User = useSelector((state) => state.auth.user);
	const online = useSelector((state) => state.online.online);
	const ActiveCred = useSelector((state) => state.cred.activeCred);
	const Branchs = useSelector((state) => state.branch.branchs);

	const SearchBranch = Branchs.filter(
		(branch) =>
			(branch.name.toLowerCase().search(Search.toLowerCase()) > -1 ||
				Search === "") &&
			parseInt(branch.instLinker) === parseInt(ActiveCred.instLinker) &&
			parseInt(branch.deleted) === 0
	).sort((a, b) => parseInt(b.linker) - parseInt(a.linker));

	return (
		<div>
			<p className="text-center h6 text-primary text-capitalize">
				Institution Branches
			</p>
			<table className="table table-sm" id="branches">
				<thead className="thead-dark">
					<tr>
						<th scope="column" colSpan={2}>
							<input
								type={"text"}
								placeholder={`Search Branch`}
								value={Search}
								onChange={(e) => setSearch(e.target.value)}
								className="rounded form-control"
							/>
						</th>
						<td>
							<button
								className="btn btn-sm btn-primary"
								onClick={() => {
									setShowModal(true);
									setType("add");
									setBranch({ name: "", details: "" });
								}}
							>
								<PlusCircle /> Add Branch
							</button>
						</td>
					</tr>
				</thead>
				{SearchBranch.length > 0 ? (
					<tbody>
						{SearchBranch.map((branch, index) => (
							<tr key={index} className="item">
								<td>{branch.name}</td>
								<td>{branch.details}</td>
								<td>
									<button
										className="btn btn-xs btn-outline-info mx-1"
										onClick={() => {
											setShowModal(true);
											setType("edit");
											setBranch({ ...branch });
										}}
									>
										{" "}
										<PencilSquare style={{ cursor: "pointer" }} />
									</button>
									<button
										className="btn btn-xs btn-outline-danger mx-1"
										onClick={() => {
											setShowModal(true);
											setType("delete");
											setBranch({ ...branch });
										}}
									>
										{" "}
										<Trash style={{ cursor: "pointer" }} />
									</button>
								</td>
							</tr>
						))}
					</tbody>
				) : (
					<NoBranch></NoBranch>
				)}
			</table>
			<GeneratePdf id={"branches"} name={"branches"}></GeneratePdf>
			<BranchCrud
				setShowModal={setShowModal}
				ShowModal={ShowModal}
				branch={Branch}
				setBranch={setBranch}
				type={Type}
			></BranchCrud>
		</div>
	);
};

export default Branchs;
