import React from "react";
import Accordion from "react-bootstrap/Accordion";

const Details = () => {
	return (
		<div className="row justify-content-center">
			<div className="col-md-10 col-lg-8">
				<Accordion>
					<Accordion.Item eventKey="1">
						<Accordion.Header>
							<strong>Step 1 : How It Works</strong>
						</Accordion.Header>
						<Accordion.Body>
							<ul className="list-group-flush">
								<li className="list-item">
									To use mpesa online you need to acquire a Paybill
								</li>
								<li className="list-item">
									Clients can pay to your Paybill and use their ACCOUNTNO joined
									with account First Two letters as account.
								</li>
								<li className="list-item">
									The system will capture the details internally
								</li>
								<li className="list-item">
									To get Paybill you can visit nearest safaricom shop or apply
									it online at{" "}
									<span className="btn-link text-primary">
										https://m-pesaforbusiness.co.ke
									</span>
								</li>

								<li className="list-item text-decoration-underline">
									{" "}
									<strong>
										<span className="h5">NB : </span>
										<span className="h6">
											We Do Not Save These Keys Or Paybill Or Receive Funds On
											Your Behalf
										</span>
									</strong>
								</li>
							</ul>
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="2">
						<Accordion.Header>
							<strong>Step 2 : Mpesa Portal Credentials</strong>
						</Accordion.Header>
						<Accordion.Body>
							<ul className="list-group-flush">
								<li className="list-item">
									After acquiring the paybill you will need Mpesa Portal Login
									Credentials
								</li>
								<li>
									To get this Credentials you can visit nearest safaricom shop
									or email safaricom at{" "}
									<span className="btn-link text-primary">
										M-PESABusiness@safaricom.co.ke
									</span>{" "}
									and request for Mpesa portal credentials and they shall guide
									you through
								</li>
								<li>
									{" "}
									After getting the credentials which is a username and password
									, you can activate them by visiting{" "}
									<span className="btn-link text-primary">
										https://org.ke.m-pesa.com
									</span>{" "}
								</li>
							</ul>
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="3">
						<Accordion.Header>
							<strong>Step 3 : Daraja Registration</strong>
						</Accordion.Header>
						<Accordion.Body>
							<ul className="list-group-flush">
								<li className="list-item">
									To get app credentials visit{" "}
									<span className="btn-link text-primary">
										https://developer.safaricom.co.ke/
									</span>{" "}
									and create an account
								</li>
							</ul>
						</Accordion.Body>
					</Accordion.Item>{" "}
					<Accordion.Item eventKey="5">
						<Accordion.Header>
							<strong>Step 4 : Live Connection</strong>
						</Accordion.Header>
						<Accordion.Body>
							<ul className="list-group-flush">
								<li className="list-item">
									Now to get credentials for your paybill on the daraja portal
									click on Go Live
								</li>
								<li className="list-item">
									Here You Will be requested to enter your shortcode/paybill,
									your organization name and the Mpesa portal username that you
									received from safaricom on Step 2
								</li>
								<li className="list-item">
									After this and within 24 hours you will receive an email from
									safaricom of your actual Pass key and lists of urls to use
								</li>
								<li className="list-item">
									For consumer secret you will check at your daraja portal my
									apps and apart from sandbox apps you will find another app
									with your business name, you can use those keys for live
									testing and Confirmation
								</li>{" "}
								<li className="list-item">
									If At Any Point You Wish To Stop It Or Change It, You Can Go
									To Your Daraja Portal, Self Service And Delete Urls
								</li>
								<li className="list-item text-decoration-underline">
									{" "}
									<strong>
										<span className="h5">NB : </span>
										<span className="h6">
											We Do Not Save These Keys Or Paybill Or Receive Funds On
											Your Behalf
										</span>
									</strong>
								</li>
							</ul>
						</Accordion.Body>
					</Accordion.Item>
				</Accordion>
			</div>
		</div>
	);
};

export default Details;
