import React from "react";
import { useSelector } from "react-redux";
import { Trash, PencilSquare } from "react-bootstrap-icons";
import { Dropdown } from "react-bootstrap";

const ClientApplication = ({
  application,
  setShowModal,
  setType,
  setApplication,
}) => {
  const Creds = useSelector((state) => state.cred.creds);
  const Accounts = useSelector((state) => state.account.accounts).filter(
    (account) => parseInt(account.deleted) === 0
  );
  const FindUser = (linker) => {
    let cred = Creds.find((cred) => parseInt(cred.linker) == parseInt(linker));

    return cred || { firstname: "unknown" };
  };

  const FindAccount = (linker) => {
    let account = Accounts.find(
      (account) => parseInt(account.linker) === parseInt(linker)
    );

    return account || { name: "unknown" };
  };

  return (
    <tr className="item">
      <td>{application.status}</td>
      <td>
        {FindAccount(application.accountLinker).status === "loan" ? (
          <Dropdown>
            <Dropdown.Toggle variant="transparent">
              {application.details}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>
                <strong>Security : </strong>
                <em>{application.security}</em>
              </Dropdown.Item>
              <Dropdown.Item>
                <strong>Guarantors : </strong>
                <em>{application.guarantors} </em>
              </Dropdown.Item>
              <Dropdown.Item>
                <strong>Principal Amount: </strong>
                <em>{application.principal} </em>
              </Dropdown.Item>
              <Dropdown.Item>
                <strong>Repayment Period: </strong>
                <em>{application.period} </em>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ) : (
          application.details
        )}
      </td>
      <td>{application.appliedAmount}</td>
      <td>{application.approvedAmount} </td>
      <td>{application.disbursedAmount}</td>

      <td>{application.disburseDate}</td>
      <td>
        {new Date(parseInt(application.linker)).toLocaleDateString("en-US")}
      </td>
      <td>{FindUser(application.credLinker).email}</td>
      <td>
        {application.status === "inReview" ? (
          <span
            onClick={() => {
              setShowModal(true);
              setType("review");
              setApplication({
                ...application,
                client: FindUser(application.clientLinker),
                status: "reviewed",
              });
            }}
            className="btn-link"
            style={{ cursor: "pointer" }}
          >
            Review
          </span>
        ) : null}
        {application.status === "reviewed" ? (
          <span
            onClick={() => {
              setShowModal(true);
              setType("disburse");
              setApplication({
                ...application,
                client: FindUser(application.clientLinker),
                status: "disbursed",
              });
            }}
            className="btn-link"
            style={{ cursor: "pointer" }}
          >
            Disburse
          </span>
        ) : null}
        <PencilSquare
          onClick={() => {
            setShowModal(true);
            setType("edit");
            setApplication({
              ...application,
              client: FindUser(application.clientLinker),
            });
          }}
          style={{ cursor: "pointer" }}
          className="text-info mx-2"
        />

        <Trash
          onClick={() => {
            setShowModal(true);
            setType("delete");
            setApplication({
              ...application,
              client: FindUser(application.clientLinker),
            });
          }}
          style={{ cursor: "pointer" }}
          className="text-danger mx-2"
        />
      </td>
    </tr>
  );
};

export default ClientApplication;
