import { createSlice } from "@reduxjs/toolkit";

export const AccountSlice = createSlice({
  name: "account",
  initialState: {
    accounts: [],
  },
  reducers: {
    fetchAccounts: (state, action) => {
      state.accounts = action.payload;
    },
    addAccount: (state, action) => {
      state.accounts = [action.payload, ...state.accounts];
    },
    updateAccount: (state, action) => {
      let index = state.accounts.findIndex(
        (account) =>
          parseInt(account.linker) === parseInt(action.payload.linker)
      );

      let prevAccounts = [...state.accounts];

      prevAccounts.splice(
        index < 0 ? 1 : index,
        index < 0 ? 0 : 1,
        action.payload
      );

      state.accounts = prevAccounts;
    },
    deleteAccount: (state, action) => {
      let newAccounts = state.accounts.filter(
        (account) =>
          parseInt(account.linker) !== parseInt(action.payload.linker)
      );
      state.accounts = newAccounts;
    },
  },
});

export const { fetchAccounts, addAccount, updateAccount, deleteAccount } =
  AccountSlice.actions;

export default AccountSlice.reducer;
