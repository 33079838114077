import React, { useState } from "react";
import DividendModal from "./DividendModal";
import { useSelector } from "react-redux";
import GeneratePdf from "../../../Utils/GeneratePdf";
import SaveModal from "./SaveModal";
import { PencilSquare, Trash } from "react-bootstrap-icons";
import DividendCrud from "./DividendCrud";
import { Dropdown } from "react-bootstrap";

const Dividends = () => {
	const Entries = useSelector((state) => state.entry.entrys).filter(
		(entry) => parseInt(entry.deleted) === 0
	);
	const [DividendPeriod, setDividendPeriod] = useState("all");
	const [ActiveClient, setActiveClient] = useState({});
	const [ActiveDevidend, setActiveDevidend] = useState({});
	const [DividendCrudModal, setDividendCrudModal] = useState(false);
	const [DividendCrudType, setDividendCrudType] = useState("edit");

	const DividendsEntries = Entries.filter(
		(entry) => entry.type === "dividend"
	).sort((a, b) => parseInt(b.linker) - parseInt(a.linker));

	const inst = useSelector((state) => state.inst.activeInst);
	const Accounts = useSelector((state) => state.account.accounts).filter(
		(account) => parseInt(account.deleted) === 0
	);
	const [Search, setSearch] = useState("");
	const Groups = useSelector((state) => state.group.groups).filter(
		(group) => parseInt(group.deleted) === 0
	);
	const [ShowModal, setShowModal] = useState(false);
	const [ShowSaveModal, setShowSaveModal] = useState(false);
	const [Account, setAccount] = useState("");
	const [Group, setGroup] = useState("all");
	const [ClientsDividends, setClientsDividends] = useState([]);
	const Clients = useSelector((state) => state.cred.creds)
		.filter(
			(client) =>
				client.credLinker != "0" &&
				parseInt(client.deleted) === 0 &&
				parseInt(client.client) === 1 &&
				(parseInt(client.groupLinker) === parseInt(Group) || Group === "all") &&
				parseInt(client.linker) !== parseInt(inst.linker) &&
				((client.clientReg || "").toLowerCase().search(Search.toLowerCase()) >
					-1 ||
					(client.firstname || "").toLowerCase().search(Search.toLowerCase()) >
						-1 ||
					(client.lastname || "").toLowerCase().search(Search.toLowerCase()) >
						-1 ||
					(client.surname || "").toLowerCase().search(Search.toLowerCase()) >
						-1 ||
					(client.email || "").toLowerCase().search(Search.toLowerCase()) >
						-1 ||
					(client.contact || "").toLowerCase().search(Search.toLowerCase()) >
						-1 ||
					Search === "")
		)
		.sort((a, b) => parseInt(b.linker) - parseInt(a.linker));
	const [Data, setData] = useState([]);
	const [FromDate, setFromDate] = useState(
		new Date(new Date().setFullYear(new Date().getFullYear() - 1))
			.toISOString()
			.slice(0, 7)
	);
	const [ToDate, setToDate] = useState(
		new Date(new Date().setFullYear(new Date().getFullYear()))
			.toISOString()
			.slice(0, 7)
	);
	function generateDummyData(sampleData, numberOfEntries) {
		const types = sampleData.map((item) => item.type);
		const accountLinkers = sampleData.map((item) => item.accountLinker);
		const clientLinkers = sampleData.map((item) => item.clientLinker);
		const credLinkers = sampleData.map((item) => item.credLinker);
		const instLinkers = sampleData.map((item) => item.instLinker);

		const twoYearsInMillis = 2 * 365 * 24 * 60 * 60 * 1000;
		const now = Date.now();

		const dummyData = [];

		for (let i = 0; i < numberOfEntries; i++) {
			const randomAmount = Math.floor(Math.random() * (10000 - 500 + 1)) + 500;
			const randomAccountLinker =
				accountLinkers[Math.floor(Math.random() * accountLinkers.length)];
			const randomClientLinker =
				clientLinkers[Math.floor(Math.random() * clientLinkers.length)];
			const randomType = types[Math.floor(Math.random() * types.length)];
			const randomCredLinker =
				credLinkers[Math.floor(Math.random() * credLinkers.length)];
			const randomInstLinker =
				instLinkers[Math.floor(Math.random() * instLinkers.length)];
			const randomDateOffset = Math.floor(Math.random() * twoYearsInMillis);
			const randomCreatedAt = new Date(now - randomDateOffset).toISOString();
			const randomUpdatedAt = new Date(
				now - randomDateOffset + Math.random() * 86400000
			).toISOString();
			const randomLinker = Math.floor((now - randomDateOffset) / 1000) * 1000; // UNIX timestamp in milliseconds

			const newData = {
				id: i + 100, // starting from 100
				amount: randomAmount.toString(),
				details: `Dummy details ${i}`,
				security: "",
				guarantors: "",
				principal: "",
				period: "",
				type: randomType,
				accountLinker: randomAccountLinker,
				clientLinker: randomClientLinker,
				branchLinker: null,
				modeLinker: "",
				instLinker: randomInstLinker,
				credLinker: randomCredLinker,
				code: `CODE${Math.floor(Math.random() * 10000)}`,
				trace: randomLinker.toString(),
				live: "1",
				linker: randomLinker,
				status: "0",
				deleted: "0",
				createdAt: randomCreatedAt,
				updatedAt: randomUpdatedAt,
			};

			dummyData.push(newData);
		}

		return dummyData;
	}
	//const [Dummy, setDummy] = useState(generateDummyData(Entries, 500));
	const [Dummy, setDummy] = useState(Entries);
	const CalculateDividends = () =>
		setClientsDividends(
			Clients.map((client) => {
				let ClientDivs = Data.map((item) => {
					let TotalSavings =
						Dummy.filter(
							(entry) =>
								//	entry.linker >= item.firstDay &&
								entry.linker <= item.lastDay &&
								parseInt(entry.accountLinker) === parseInt(Account) &&
								(entry.type === "saving" || entry.type === "savings-interest")
						).reduce((sum, entry) => sum + +entry.amount, 0) -
						Dummy.filter(
							(entry) =>
								//entry.linker >= item.firstDay &&
								entry.linker <= item.lastDay &&
								parseInt(entry.accountLinker) === parseInt(Account) &&
								(entry.type === "withdrawal" || entry.type === "fine")
						).reduce((sum, entry) => sum + +entry.amount, 0);

					let ClientSavings =
						Dummy.filter(
							(entry) =>
								//entry.linker >= item.firstDay &&
								entry.linker <= item.lastDay &&
								parseInt(entry.accountLinker) === parseInt(Account) &&
								parseInt(entry.clientLinker) === parseInt(client.linker) &&
								(entry.type === "saving" || entry.type === "savings-interest")
						).reduce((sum, entry) => sum + +entry.amount, 0) -
						Dummy.filter(
							(entry) =>
								//entry.linker >= item.firstDay &&
								entry.linker <= item.lastDay &&
								parseInt(entry.accountLinker) === parseInt(Account) &&
								parseInt(entry.clientLinker) === parseInt(client.linker) &&
								(entry.type === "withdrawal" || entry.type === "fine")
						).reduce((sum, entry) => sum + +entry.amount, 0);

					let clientDiv =
						TotalSavings > 0 ? (ClientSavings * item.div) / TotalSavings : 0;

					return {
						...item,
						totalSavings: TotalSavings,
						clientSavings: ClientSavings,
						clientDiv,
					};
				});
				return {
					...client,
					clientDivs: ClientDivs,
					TotalClientDivs: ClientDivs.reduce(
						(sum, entry) => sum + +entry.clientDiv,
						0
					),
				};
			})
		);

	const FilteredDividends = DividendsEntries.filter(
		(dividend) =>
			(DividendPeriod === "all" || dividend.accountLinker === DividendPeriod) &&
			Clients.some(
				(client) => parseInt(client.linker) === parseInt(dividend.clientLinker)
			)
	);

	const GetClient = (clientLinker) =>
		Clients.find(
			(client) => parseInt(client.linker) === parseInt(clientLinker)
		);

	const GetGroup = (groupLinker) =>
		Groups.find((group) => parseInt(group.linker) === parseInt(groupLinker));

	return (
		<div>
			<div className="d-flex justify-content-around p-3">
				<div>
					<p className="h6 text-center text-decoration-underline">
						Issued Dividends
					</p>
				</div>

				<div className="d-flex">
					<div>
						<button
							className="btn btn-sm btn-outline-success rounded"
							onClick={() => setShowModal(true)}
						>
							Calculate Dividend
						</button>
					</div>
					<div>
						<GeneratePdf
							id={"dividend"}
							name={`${
								Groups.find(
									(group) => parseInt(group.linker) === parseInt(Group)
								)?.name || "All-Groups"
							}-${DividendPeriod}-dividend`}
						></GeneratePdf>
					</div>
				</div>
			</div>
			<div
				className="row justify-content-center border-1 border-dark"
				id="dividend"
			>
				<div className="col-md-8 col-lg-6">
					<table className="table table-sm table-responsive">
						<thead>
							<tr>
								<th colSpan={2}>
									<input
										type={"text"}
										placeholder={`Search Client`}
										value={Search}
										onChange={(e) => setSearch(e.target.value)}
										className="rounded form-control m-0 p-0"
									/>
								</th>

								{Groups.length > 0 ? <th>Group</th> : null}
								<th>Period</th>
								<th></th>
								<th>Dividend</th>
							</tr>
							<tr>
								<th>AC No</th>
								<th>Name</th>
								{Groups.length > 0 ? (
									<th>
										{" "}
										{Groups.length > 0 ? (
											<th>
												<select
													className="form-control rounded m-0 p-0"
													value={Group}
													onChange={(e) => {
														setGroup(e.target.value);
														setSearch("");
													}}
												>
													<option value={"all"}>All Groups</option>
													{Groups.map((group) => (
														<option key={group.linker} value={group.linker}>
															{group.name}
														</option>
													))}
												</select>
											</th>
										) : null}
									</th>
								) : null}
								<th>
									<select
										value={DividendPeriod}
										className="form-control m-0 p-0"
										onChange={(e) => setDividendPeriod(e.target.value)}
									>
										<option value={"all"}>All Periods</option>
										{[
											...new Set(
												DividendsEntries.map(
													(dividend) => dividend.accountLinker
												)
											),
										].map((dividend) => (
											<option value={dividend}>{dividend}</option>
										))}
									</select>
								</th>
								<th>Date</th>
								<th>
									{FilteredDividends.reduce(
										(sum, dividend) => sum + +dividend.amount,
										0
									)}
								</th>
							</tr>
						</thead>
						<tbody>
							{FilteredDividends.map((dividend) => (
								<tr>
									<td>{GetClient(dividend.clientLinker)?.clientReg}</td>
									<td className="text-capitalize">
										{GetClient(dividend.clientLinker)?.firstname}{" "}
										{GetClient(dividend.clientLinker)?.lastname}
									</td>
									{Groups.length > 0 ? (
										<td>
											{
												GetGroup(GetClient(dividend.clientLinker)?.groupLinker)
													?.name
											}
										</td>
									) : null}
									<td>{dividend.accountLinker}</td>
									<td>
										{new Date(parseInt(dividend.linker)).toLocaleDateString(
											"en-US"
										)}
									</td>
									<td className="d-flex">
										<Dropdown variant="transparent">
											<Dropdown.Toggle
												className="text-left m-0 p-0"
												variant="transparent"
											>
												{dividend.amount}
											</Dropdown.Toggle>
											<Dropdown.Menu variant="transparent">
												<table className="table table-sm table-bordered table-responsive">
													{JSON.parse(dividend.details).map((entry) => (
														<tr>
															<td>
																{entry.month}/{entry.year}
															</td>
															<td>{parseInt(entry.clientDiv)}</td>
														</tr>
													))}
												</table>
											</Dropdown.Menu>
										</Dropdown>
										<PencilSquare
											className="text-primary m-1"
											style={{ cursor: "pointer" }}
											onClick={() => {
												setActiveClient(GetClient(dividend.clientLinker) || {});
												setActiveDevidend(dividend);
												setDividendCrudType("edit");
												setDividendCrudModal(true);
											}}
										/>{" "}
										<Trash
											className="text-danger m-1"
											style={{ cursor: "pointer" }}
											onClick={() => {
												setActiveClient(GetClient(dividend.clientLinker) || {});
												setActiveDevidend(dividend);
												setDividendCrudType("delete");
												setDividendCrudModal(true);
											}}
										/>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
			<DividendCrud
				Client={ActiveClient}
				Dividend={ActiveDevidend}
				setDividend={setActiveDevidend}
				Type={DividendCrudType}
				setShowModal={setDividendCrudModal}
				ShowModal={DividendCrudModal}
			></DividendCrud>

			<DividendModal
				ShowModal={ShowModal}
				setShowModal={setShowModal}
				Account={Account}
				setAccount={setAccount}
				Data={Data}
				setData={setData}
				FromDate={FromDate}
				setFromDate={setFromDate}
				ToDate={ToDate}
				setToDate={setToDate}
				Group={Group}
				setGroup={setGroup}
				Entries={Dummy}
				Clients={Clients}
				CalculateDividends={CalculateDividends}
				setShowSaveModal={setShowSaveModal}
			></DividendModal>
			<SaveModal
				Clients={Clients}
				Group={Group}
				Dividends={ClientsDividends}
				Account={Account}
				ShowModal={ShowSaveModal}
				setShowModal={setShowSaveModal}
				FromDate={FromDate}
				ToDate={ToDate}
				setClientsDividends={setClientsDividends}
			></SaveModal>
		</div>
	);
};

export default Dividends;
