import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { ApiUrl } from "../../Network/Urls";
import swal from "sweetalert";
import SubPay2 from "./SubPay2";
import Header from "../../components/Header";
import { CountryCities } from "../../Utils/CountryCities";
import { CountryList } from "../../Utils/CountryList";

const SoftwarePay2 = () => {
	useEffect(() => {
		GetExchangeRate();
	}, []);

	const [Loading, setLoading] = useState(false);
	const [SoftwareId, setSoftwareId] = useState("");
	const [ConfirmId, setConfirmId] = useState("");
	const [Pay, setPay] = useState(false);
	const [Error, setError] = useState(false);

	const [Subs, setSubs] = useState([]);
	const [Agent, setAgent] = useState({ cost: 72 });
	const [Rate, setRate] = useState(0);
	const [Currency, setCurrency] = useState("USD");
	const [Fetching, setFetching] = useState(false);

	const HandleSubmit = async (e) => {
		e.preventDefault();
		if (SoftwareId !== ConfirmId) {
			setError(true);
			return;
		}
		setLoading(true);
		await GetSubs();
		await GetAgent();

		setPay(true);
		setLoading(false);
	};
	const GetSubs = async () => {
		await fetch(`${ApiUrl}/sub/fetch`, {
			method: "POST",
			headers: {
				"content-type": "application/json",
				authorization: `bearer `,
			},
			body: JSON.stringify({
				softwareId: `${parseInt(SoftwareId)}`.split("").reverse().join(""),
			}),
		})
			.then((res) => res.json())
			.then((res) => {
				if (res.status === 200) {
					setSubs(res.subs);
				} else {
					swal({
						title: "Failed to connect",
						icon: "warning",
						timer: 3000,
					});
				}
			})
			.catch(() => {
				swal({
					title: "Failed to connect",
					icon: "warning",
					timer: 3000,
				});
			});
	};
	const GetExchangeRate = async () => {
		setFetching(true);
		await fetch(`${ApiUrl}/sub/exchange`, {
			method: "POST",
			headers: {
				"content-type": "application/json",
				authorization: `bearer `,
			},
			body: JSON.stringify({}),
		})
			.then((res) => res.json())
			.then((res) => {
				setFetching(false);
				if (res.status === 200) {
					if (Intl) {
						let TzArr = Intl.DateTimeFormat()
							.resolvedOptions()
							.timeZone.split("/");
						let userCity = TzArr[TzArr.length - 1];
						let userCountry = CountryCities[userCity];
						let userCurrency = CountryList.find(
							(country) =>
								country.countryName.toLowerCase() === userCountry.toLowerCase()
						);

						setCurrency(userCurrency.currencyCode || Currency);

						setRate(res.data[(userCurrency || {}).currencyCode] || Rate);
					}
				} else {
					swal({
						title: "Failed to connect",
						icon: "warning",
						timer: 3000,
					});
				}
			})
			.catch(() => {
				setFetching(false);
				swal({
					title: "Failed to connect",
					icon: "warning",
					timer: 3000,
				});
			});
	};
	const GetAgent = async () => {
		await fetch(`${ApiUrl}/agent/inst`, {
			method: "POST",
			headers: {
				"content-type": "application/json",
				authorization: `bearer `,
			},
			body: JSON.stringify({
				softwareId: SoftwareId.split("").reverse().join(""),
			}),
		})
			.then((res) => res.json())
			.then((res) => {
				if (res.agent) {
					setAgent(res.agent);
				}
			})
			.catch();
	};

	return (
		<div>
			<Header></Header>
			<div
				className="row d-flex justify-content-center"
				style={{ marginTop: "40px" }}
			>
				<div className="col-md-8">
					<div className="card">
						<div className="card-header">
							<h6 className="text-center">
								Activate Finance Techsystem Software
							</h6>
						</div>
						<div className="card-body">
							{Fetching ? (
								<div className="jumbotron">
									<p className="text-center">
										<span className="spinner-border h5 text-primary"></span>
									</p>
								</div>
							) : Pay ? (
								<SubPay2
									subs={Subs}
									updatePay={setPay}
									amount={Agent.cost}
									softwareId={SoftwareId}
									currency={Currency}
									rate={Rate}
								></SubPay2>
							) : (
								<Form onSubmit={HandleSubmit}>
									<div className="form-group">
										<label className="mb-2 text-capitalize">Software Id:</label>
										<input
											className="rounded form-control"
											placeholder={`enter software id`}
											value={SoftwareId}
											onChange={(e) => setSoftwareId(e.target.value)}
											required
										/>
										<hr />
									</div>
									<div className="form-group">
										<label className="mb-2 text-capitalize">
											Confirm Software Id:
											{Error ? (
												<small className="text-danger">
													<em>Not Matching</em>
												</small>
											) : null}
										</label>
										<input
											className="rounded form-control"
											placeholder={`confirm software id`}
											value={ConfirmId}
											onChange={(e) => setConfirmId(e.target.value)}
											required
										/>
									</div>
									<hr />
									<div className="d-flex justify-content-around mb-2">
										{Loading ? (
											<span className="spinner-border text-primary"></span>
										) : (
											<Button variant="primary" type="submit">
												Next
											</Button>
										)}
									</div>
								</Form>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SoftwarePay2;
